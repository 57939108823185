import * as React from 'react';
import styled from 'styled-components';
import { Small } from './text';
import { IconWrapper } from './IconWrapper';
import { theme } from '../theme';

export const AnnouncementBanner = React.forwardRef(({ title, link, onDismiss, show }, externalRef) => {
  const backupRef = React.useRef(null);
  const ref = externalRef ? externalRef : backupRef;
  
  const dismissBanner = () => {
    if (ref && ref.current) {
      ref.current.style.transition = '200ms ease-out';
      ref.current.style.maxHeight = '0px';
    }

    onDismiss();
  };

  return (
    <Banner ref={ref} show={show}>
      <Small>
        {title}
        <CallToActionScreenSm weight="medium" css={{ marginLeft: '12px' }}>
          {link}
        </CallToActionScreenSm>
      </Small>
      <CallToActionScreenLg weight="medium">
        {link}
      </CallToActionScreenLg>
      <Icon onClick={dismissBanner}>
        <XIcon />
      </Icon>
    </Banner>
  );
});

const Banner = styled.div`
  display: ${({ show }) => show ? 'flex' : 'none'};
  position: relative;
  width: 100%;
  height: ${({ theme }) => theme.spacing(6)};
  max-height: ${({ theme }) => theme.spacing(25)};
  background-color: ${({ theme }) => theme.color.blue40};
  padding-left: ${({ theme }) => theme.spacing(10)};
  padding-right: ${({ theme }) => theme.spacing(10)};
  justify-content: center;
  align-items: center;

  ${Small} {
    svg {
      transition: 200ms ease-out;
      margin-left: ${({ theme }) => theme.spacing(0.5)};
      stroke-width: 1.5px;
    }
  }

  * {
    color: ${({ theme }) => theme.color.white};
  }

  @media ${({ theme }) => theme.breakpoints.md} {
    flex-direction: column;
    justify-content: center;
    height: ${({ theme }) => theme.spacing(9)};
  }

  @media ${({ theme }) => theme.breakpoints.sm} {
    ${Small} {
      display: inline;
      text-align: center;
    }
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    padding-left: ${({ theme }) => theme.spacing(6)};
    padding-right: ${({ theme }) => theme.spacing(6)};
  }
`;

const CallToAction = styled(Small)`
  align-items: center;

  &:hover {
    cursor: pointer;
    svg {
      transform: translateX(8px);
    }
  }

  a {
    color: ${({ theme }) => theme.color.white};
    display: flex;
    align-items: center;
  }
`;

const CallToActionScreenLg = styled(CallToAction)`
  display: flex !important;
  margin-left: ${({ theme }) => theme.spacing(1)};

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: none !important;
  }
`

const CallToActionScreenSm = styled(CallToAction)`
  display: none !important;

  @media ${({ theme }) => theme.breakpoints.sm} {
    display: inline-flex !important;
  }
`

const Icon = styled.div`
  position: absolute;
  right: 24px;

  @media ${({ theme }) => theme.breakpoints.md} {
    top: 12px;
  }

  @media ${({ theme }) => theme.breakpoints.xs} {
    right: 12px;
  }

  * {
    stroke-width: 1.75px;
    stroke: white;
  }
`;

const XIconWrapper = styled(IconWrapper)`
  cursor: pointer;
  & path {
    transition: ${props => props.theme.transitions.stroke};
  }
  &:hover {
    & path {
      stroke: ${props => props.theme.color.grey40};
    }
  }
`;

export const XIcon = () => (
  <XIconWrapper>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 17L16.9998 7.00025" stroke="#A4AEBD" strokeWidth="1.75" strokeLinecap="round" />
      <path d="M7 7L16.9998 16.9997" stroke="#A4AEBD" strokeWidth="1.75" strokeLinecap="round" />
    </svg>
  </XIconWrapper>
);
