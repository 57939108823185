import React from 'react';

export const NamebaseLogo = () => (
  <svg width="172" height="24" viewBox="0 0 172 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9629 4.59767C20.2023 4.59767 21.207 3.56845 21.207 2.29883C21.207 1.02922 20.2023 0 18.9629 0C17.7235 0 16.7188 1.02922 16.7188 2.29883C16.7188 3.56845 17.7235 4.59767 18.9629 4.59767Z"
      fill="url(#paint0_linear)"
    />
    <path
      d="M10.2107 2.81616C15.8499 2.81616 20.4214 7.49913 20.4214 13.2759V22.931H14.1379V13.2759C14.1379 11.054 12.3796 9.2529 10.2107 9.2529V2.81616Z"
      fill="#007FFF"
    />
    <path
      d="M10.2107 2.81616C4.5715 2.81616 0 7.49913 0 13.2759V22.931H6.28352V13.2759C6.28352 11.054 8.04179 9.2529 10.2107 9.2529V2.81616Z"
      fill="#FFB700"
    />
    <path
      d="M36.6873 7.65044C38.447 7.65044 39.869 8.22401 40.9533 9.37113C42.0375 10.5 42.5797 12.0842 42.5797 14.1235V23.0001H38.847V14.6424C38.847 13.4407 38.5537 12.5212 37.9671 11.8839C37.3805 11.2284 36.5807 10.9006 35.5675 10.9006C34.5365 10.9006 33.7189 11.2284 33.1145 11.8839C32.528 12.5212 32.2347 13.4407 32.2347 14.6424V23.0001H28.502V7.86894H32.2347V9.75351C32.7324 9.09801 33.3634 8.58817 34.1277 8.22401C34.9098 7.84163 35.763 7.65044 36.6873 7.65044Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M44.9356 15.3799C44.9356 13.8504 45.2289 12.4939 45.8155 11.3103C46.4198 10.1268 47.2286 9.21636 48.2417 8.57907C49.2727 7.94178 50.4192 7.62313 51.6812 7.62313C52.7832 7.62313 53.7431 7.85073 54.5607 8.30594C55.3961 8.76115 56.0627 9.33471 56.5604 10.0266V7.86894H60.3198V23.0001H56.5604V20.7878C56.0805 21.4979 55.4139 22.0897 54.5607 22.5631C53.7253 23.0183 52.7566 23.2459 51.6545 23.2459C50.4103 23.2459 49.2727 22.9181 48.2417 22.2626C47.2286 21.6071 46.4198 20.6876 45.8155 19.5041C45.2289 18.3023 44.9356 16.9276 44.9356 15.3799ZM56.5604 15.4345C56.5604 14.5059 56.3826 13.7138 56.0271 13.0583C55.6716 12.3846 55.1917 11.8748 54.5874 11.5288C53.983 11.1647 53.3342 10.9826 52.641 10.9826C51.9478 10.9826 51.3079 11.1555 50.7213 11.5015C50.1348 11.8475 49.6548 12.3573 49.2816 13.031C48.9261 13.6865 48.7483 14.4695 48.7483 15.3799C48.7483 16.2903 48.9261 17.0915 49.2816 17.7834C49.6548 18.4571 50.1348 18.976 50.7213 19.3402C51.3257 19.7044 51.9656 19.8864 52.641 19.8864C53.3342 19.8864 53.983 19.7135 54.5874 19.3675C55.1917 19.0033 55.6716 18.4935 56.0271 17.838C56.3826 17.1643 56.5604 16.3631 56.5604 15.4345Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M82.0753 7.65044C83.8883 7.65044 85.3459 8.22401 86.4479 9.37113C87.5677 10.5 88.1276 12.0842 88.1276 14.1235V23.0001H84.3949V14.6424C84.3949 13.4589 84.1016 12.5576 83.515 11.9385C82.9285 11.3012 82.1286 10.9826 81.1154 10.9826C80.1023 10.9826 79.2935 11.3012 78.6892 11.9385C78.1026 12.5576 77.8093 13.4589 77.8093 14.6424V23.0001H74.0766V14.6424C74.0766 13.4589 73.7833 12.5576 73.1967 11.9385C72.6101 11.3012 71.8103 10.9826 70.7971 10.9826C69.7662 10.9826 68.9485 11.3012 68.3442 11.9385C67.7576 12.5576 67.4643 13.4589 67.4643 14.6424V23.0001H63.7316V7.86894H67.4643V9.69888C67.9442 9.06159 68.5575 8.56086 69.304 8.19669C70.0683 7.83253 70.9037 7.65044 71.8103 7.65044C72.9656 7.65044 73.9966 7.90536 74.9031 8.41519C75.8096 8.90682 76.5117 9.61694 77.0094 10.5456C77.4893 9.67157 78.1826 8.97055 79.0891 8.4425C80.0134 7.91446 81.0088 7.65044 82.0753 7.65044Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M105.167 15.1068C105.167 15.653 105.131 16.1446 105.06 16.5816H94.2621C94.351 17.6741 94.7243 18.5299 95.3819 19.149C96.0396 19.7681 96.8484 20.0776 97.8082 20.0776C99.1946 20.0776 100.181 19.4677 100.768 18.2477H104.794C104.367 19.7044 103.549 20.9061 102.341 21.8529C101.132 22.7816 99.6479 23.2459 97.8882 23.2459C96.4662 23.2459 95.1864 22.9272 94.0488 22.2899C92.929 21.6344 92.0491 20.7149 91.4092 19.5314C90.7871 18.3478 90.476 16.9822 90.476 15.4345C90.476 13.8686 90.7871 12.4939 91.4092 11.3103C92.0314 10.1268 92.9023 9.21636 94.0221 8.57907C95.142 7.94178 96.4306 7.62313 97.8882 7.62313C99.2924 7.62313 100.546 7.93267 101.648 8.55175C102.767 9.17084 103.629 10.0539 104.234 11.2011C104.856 12.33 105.167 13.6319 105.167 15.1068ZM101.301 14.0143C101.283 13.031 100.937 12.248 100.261 11.6654C99.5857 11.0645 98.7592 10.7641 97.7815 10.7641C96.8572 10.7641 96.0751 11.0554 95.4352 11.6381C94.8131 12.2025 94.431 12.9946 94.2888 14.0143H101.301Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M111.39 10.0813C111.87 9.35292 112.528 8.76115 113.363 8.30594C114.217 7.85073 115.185 7.62313 116.27 7.62313C117.532 7.62313 118.669 7.94178 119.682 8.57907C120.713 9.21636 121.522 10.1268 122.109 11.3103C122.713 12.4757 123.015 13.8322 123.015 15.3799C123.015 16.9276 122.713 18.3023 122.109 19.5041C121.522 20.6876 120.713 21.6071 119.682 22.2626C118.669 22.9181 117.532 23.2459 116.27 23.2459C115.168 23.2459 114.199 23.0274 113.363 22.5904C112.546 22.1352 111.888 21.5525 111.39 20.8424V23.0001H107.658V2.78882H111.39V10.0813ZM119.202 15.3799C119.202 14.4695 119.016 13.6865 118.643 13.031C118.287 12.3573 117.807 11.8475 117.203 11.5015C116.616 11.1555 115.976 10.9826 115.283 10.9826C114.608 10.9826 113.968 11.1647 113.363 11.5288C112.777 11.8748 112.297 12.3846 111.924 13.0583C111.568 13.732 111.39 14.5241 111.39 15.4345C111.39 16.3449 111.568 17.137 111.924 17.8107C112.297 18.4844 112.777 19.0033 113.363 19.3675C113.968 19.7135 114.608 19.8864 115.283 19.8864C115.976 19.8864 116.616 19.7044 117.203 19.3402C117.807 18.976 118.287 18.4571 118.643 17.7834C119.016 17.1097 119.202 16.3085 119.202 15.3799Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M124.534 15.3799C124.534 13.8504 124.827 12.4939 125.414 11.3103C126.018 10.1268 126.827 9.21636 127.84 8.57907C128.871 7.94178 130.018 7.62313 131.28 7.62313C132.382 7.62313 133.341 7.85073 134.159 8.30594C134.995 8.76115 135.661 9.33471 136.159 10.0266V7.86894H139.918V23.0001H136.159V20.7878C135.679 21.4979 135.012 22.0897 134.159 22.5631C133.324 23.0183 132.355 23.2459 131.253 23.2459C130.009 23.2459 128.871 22.9181 127.84 22.2626C126.827 21.6071 126.018 20.6876 125.414 19.5041C124.827 18.3023 124.534 16.9276 124.534 15.3799ZM136.159 15.4345C136.159 14.5059 135.981 13.7138 135.626 13.0583C135.27 12.3846 134.79 11.8748 134.186 11.5288C133.581 11.1647 132.933 10.9826 132.239 10.9826C131.546 10.9826 130.906 11.1555 130.32 11.5015C129.733 11.8475 129.253 12.3573 128.88 13.031C128.524 13.6865 128.347 14.4695 128.347 15.3799C128.347 16.2903 128.524 17.0915 128.88 17.7834C129.253 18.4571 129.733 18.976 130.32 19.3402C130.924 19.7044 131.564 19.8864 132.239 19.8864C132.933 19.8864 133.581 19.7135 134.186 19.3675C134.79 19.0033 135.27 18.4935 135.626 17.838C135.981 17.1643 136.159 16.3631 136.159 15.4345Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M148.982 23.2459C147.774 23.2459 146.689 23.0274 145.73 22.5904C144.77 22.1352 144.005 21.5252 143.437 20.7604C142.886 19.9957 142.583 19.149 142.53 18.2204H146.289C146.361 18.803 146.636 19.2856 147.116 19.6679C147.614 20.0503 148.227 20.2415 148.956 20.2415C149.667 20.2415 150.218 20.0958 150.609 19.8045C151.018 19.5132 151.222 19.1399 151.222 18.6847C151.222 18.1931 150.973 17.8289 150.475 17.5922C149.996 17.3373 149.222 17.0642 148.156 16.7728C147.054 16.4997 146.147 16.2175 145.436 15.9261C144.743 15.6348 144.139 15.1887 143.623 14.5878C143.126 13.9869 142.877 13.1767 142.877 12.157C142.877 11.3194 143.108 10.5547 143.57 9.86275C144.05 9.17084 144.725 8.62459 145.596 8.22401C146.485 7.82342 147.525 7.62313 148.716 7.62313C150.475 7.62313 151.88 8.07834 152.928 8.98876C153.977 9.88096 154.555 11.0918 154.661 12.6213H151.089C151.035 12.0204 150.787 11.547 150.342 11.2011C149.916 10.8369 149.338 10.6548 148.609 10.6548C147.934 10.6548 147.409 10.7823 147.036 11.0372C146.681 11.2921 146.503 11.6472 146.503 12.1024C146.503 12.6122 146.752 13.0037 147.249 13.2768C147.747 13.5317 148.52 13.7958 149.569 14.0689C150.635 14.342 151.515 14.6242 152.209 14.9156C152.902 15.2069 153.497 15.6621 153.995 16.2812C154.51 16.8821 154.777 17.6832 154.795 18.6847C154.795 19.5587 154.555 20.3417 154.075 21.0336C153.613 21.7255 152.937 22.2717 152.049 22.6723C151.178 23.0547 150.156 23.2459 148.982 23.2459Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M171.356 15.1068C171.356 15.653 171.321 16.1446 171.249 16.5816H160.451C160.54 17.6741 160.913 18.5299 161.571 19.149C162.229 19.7681 163.037 20.0776 163.997 20.0776C165.384 20.0776 166.37 19.4677 166.957 18.2477H170.983C170.556 19.7044 169.739 20.9061 168.53 21.8529C167.321 22.7816 165.837 23.2459 164.077 23.2459C162.655 23.2459 161.375 22.9272 160.238 22.2899C159.118 21.6344 158.238 20.7149 157.598 19.5314C156.976 18.3478 156.665 16.9822 156.665 15.4345C156.665 13.8686 156.976 12.4939 157.598 11.3103C158.22 10.1268 159.091 9.21636 160.211 8.57907C161.331 7.94178 162.62 7.62313 164.077 7.62313C165.481 7.62313 166.735 7.93267 167.837 8.55175C168.956 9.17084 169.819 10.0539 170.423 11.2011C171.045 12.33 171.356 13.6319 171.356 15.1068ZM167.49 14.0143C167.472 13.031 167.126 12.248 166.45 11.6654C165.775 11.0645 164.948 10.7641 163.971 10.7641C163.046 10.7641 162.264 11.0554 161.624 11.6381C161.002 12.2025 160.62 12.9946 160.478 14.0143H167.49Z"
      fill="#000C21"
      className="letters"
    />
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="14.613"
        y1="2.29162"
        x2="19.0604"
        y2="6.63317"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF5980" />
        <stop offset="1" stopColor="#FFA882" />
      </linearGradient>
    </defs>
  </svg>
);

export const NamebaseChristmasLogo = () => (
  <svg width="173" height="36" viewBox="0 0 173 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M37.6873 19.6504C39.447 19.6504 40.869 20.2239 41.9533 21.3711C43.0375 22.5 43.5797 24.0841 43.5797 26.1234V35H39.847V26.6424C39.847 25.4406 39.5537 24.5211 38.9671 23.8838C38.3805 23.2283 37.5807 22.9006 36.5675 22.9006C35.5365 22.9006 34.7189 23.2283 34.1145 23.8838C33.528 24.5211 33.2347 25.4406 33.2347 26.6424V35H29.502V19.8689H33.2347V21.7534C33.7324 21.0979 34.3634 20.5881 35.1277 20.2239C35.9098 19.8416 36.763 19.6504 37.6873 19.6504Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M45.9356 27.3798C45.9356 25.8503 46.2289 24.4938 46.8155 23.3103C47.4198 22.1267 48.2286 21.2163 49.2417 20.579C50.2727 19.9417 51.4192 19.6231 52.6812 19.6231C53.7832 19.6231 54.7431 19.8507 55.5607 20.3059C56.3961 20.7611 57.0627 21.3347 57.5604 22.0266V19.8689H61.3198V35H57.5604V32.7877C57.0805 33.4978 56.4139 34.0896 55.5607 34.563C54.7253 35.0182 53.7566 35.2458 52.6545 35.2458C51.4103 35.2458 50.2727 34.9181 49.2417 34.2626C48.2286 33.6071 47.4198 32.6875 46.8155 31.504C46.2289 30.3023 45.9356 28.9275 45.9356 27.3798ZM57.5604 27.4344C57.5604 26.5058 57.3826 25.7138 57.0271 25.0583C56.6716 24.3845 56.1917 23.8747 55.5874 23.5288C54.983 23.1646 54.3342 22.9825 53.641 22.9825C52.9478 22.9825 52.3079 23.1555 51.7213 23.5014C51.1348 23.8474 50.6548 24.3572 50.2816 25.0309C49.9261 25.6864 49.7483 26.4694 49.7483 27.3798C49.7483 28.2902 49.9261 29.0914 50.2816 29.7833C50.6548 30.457 51.1348 30.976 51.7213 31.3401C52.3257 31.7043 52.9656 31.8864 53.641 31.8864C54.3342 31.8864 54.983 31.7134 55.5874 31.3674C56.1917 31.0033 56.6716 30.4934 57.0271 29.8379C57.3826 29.1642 57.5604 28.3631 57.5604 27.4344Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M83.0753 19.6504C84.8883 19.6504 86.3459 20.2239 87.4479 21.3711C88.5677 22.5 89.1276 24.0841 89.1276 26.1234V35H85.3949V26.6424C85.3949 25.4588 85.1016 24.5575 84.515 23.9384C83.9285 23.3012 83.1286 22.9825 82.1154 22.9825C81.1023 22.9825 80.2935 23.3012 79.6892 23.9384C79.1026 24.5575 78.8093 25.4588 78.8093 26.6424V35H75.0766V26.6424C75.0766 25.4588 74.7833 24.5575 74.1967 23.9384C73.6101 23.3012 72.8103 22.9825 71.7971 22.9825C70.7662 22.9825 69.9485 23.3012 69.3442 23.9384C68.7576 24.5575 68.4643 25.4588 68.4643 26.6424V35H64.7316V19.8689H68.4643V21.6988C68.9442 21.0615 69.5575 20.5608 70.304 20.1966C71.0683 19.8325 71.9037 19.6504 72.8103 19.6504C73.9656 19.6504 74.9966 19.9053 75.9031 20.4151C76.8096 20.9068 77.5117 21.6169 78.0094 22.5455C78.4893 21.6715 79.1826 20.9705 80.0891 20.4424C81.0134 19.9144 82.0088 19.6504 83.0753 19.6504Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M106.167 27.1067C106.167 27.6529 106.131 28.1446 106.06 28.5816H95.2621C95.351 29.6741 95.7243 30.5299 96.3819 31.1489C97.0396 31.768 97.8484 32.0776 98.8082 32.0776C100.195 32.0776 101.181 31.4676 101.768 30.2476H105.794C105.367 31.7043 104.549 32.906 103.341 33.8529C102.132 34.7815 100.648 35.2458 98.8882 35.2458C97.4662 35.2458 96.1864 34.9272 95.0488 34.2899C93.929 33.6344 93.0491 32.7149 92.4092 31.5313C91.7871 30.3478 91.476 28.9822 91.476 27.4344C91.476 25.8685 91.7871 24.4938 92.4092 23.3103C93.0314 22.1267 93.9023 21.2163 95.0221 20.579C96.142 19.9417 97.4306 19.6231 98.8882 19.6231C100.292 19.6231 101.546 19.9326 102.648 20.5517C103.767 21.1708 104.629 22.0539 105.234 23.201C105.856 24.3299 106.167 25.6318 106.167 27.1067ZM102.301 26.0142C102.283 25.0309 101.937 24.248 101.261 23.6653C100.586 23.0644 99.7592 22.764 98.7815 22.764C97.8572 22.764 97.0751 23.0553 96.4352 23.638C95.8131 24.2025 95.431 24.9945 95.2888 26.0142H102.301Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M112.39 22.0812C112.87 21.3529 113.528 20.7611 114.363 20.3059C115.217 19.8507 116.185 19.6231 117.27 19.6231C118.532 19.6231 119.669 19.9417 120.682 20.579C121.713 21.2163 122.522 22.1267 123.109 23.3103C123.713 24.4756 124.015 25.8321 124.015 27.3798C124.015 28.9275 123.713 30.3023 123.109 31.504C122.522 32.6875 121.713 33.6071 120.682 34.2626C119.669 34.9181 118.532 35.2458 117.27 35.2458C116.168 35.2458 115.199 35.0273 114.363 34.5903C113.546 34.1351 112.888 33.5524 112.39 32.8423V35H108.658V14.7888H112.39V22.0812ZM120.202 27.3798C120.202 26.4694 120.016 25.6864 119.643 25.0309C119.287 24.3572 118.807 23.8474 118.203 23.5014C117.616 23.1555 116.976 22.9825 116.283 22.9825C115.608 22.9825 114.968 23.1646 114.363 23.5288C113.777 23.8747 113.297 24.3845 112.924 25.0583C112.568 25.732 112.39 26.524 112.39 27.4344C112.39 28.3449 112.568 29.1369 112.924 29.8106C113.297 30.4843 113.777 31.0033 114.363 31.3674C114.968 31.7134 115.608 31.8864 116.283 31.8864C116.976 31.8864 117.616 31.7043 118.203 31.3401C118.807 30.976 119.287 30.457 119.643 29.7833C120.016 29.1096 120.202 28.3084 120.202 27.3798Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M125.534 27.3798C125.534 25.8503 125.827 24.4938 126.414 23.3103C127.018 22.1267 127.827 21.2163 128.84 20.579C129.871 19.9417 131.018 19.6231 132.28 19.6231C133.382 19.6231 134.341 19.8507 135.159 20.3059C135.995 20.7611 136.661 21.3347 137.159 22.0266V19.8689H140.918V35H137.159V32.7877C136.679 33.4978 136.012 34.0896 135.159 34.563C134.324 35.0182 133.355 35.2458 132.253 35.2458C131.009 35.2458 129.871 34.9181 128.84 34.2626C127.827 33.6071 127.018 32.6875 126.414 31.504C125.827 30.3023 125.534 28.9275 125.534 27.3798ZM137.159 27.4344C137.159 26.5058 136.981 25.7138 136.626 25.0583C136.27 24.3845 135.79 23.8747 135.186 23.5288C134.581 23.1646 133.933 22.9825 133.239 22.9825C132.546 22.9825 131.906 23.1555 131.32 23.5014C130.733 23.8474 130.253 24.3572 129.88 25.0309C129.524 25.6864 129.347 26.4694 129.347 27.3798C129.347 28.2902 129.524 29.0914 129.88 29.7833C130.253 30.457 130.733 30.976 131.32 31.3401C131.924 31.7043 132.564 31.8864 133.239 31.8864C133.933 31.8864 134.581 31.7134 135.186 31.3674C135.79 31.0033 136.27 30.4934 136.626 29.8379C136.981 29.1642 137.159 28.3631 137.159 27.4344Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M149.982 35.2458C148.774 35.2458 147.689 35.0273 146.73 34.5903C145.77 34.1351 145.005 33.5251 144.437 32.7604C143.886 31.9956 143.583 31.1489 143.53 30.2203H147.289C147.361 30.803 147.636 31.2855 148.116 31.6679C148.614 32.0503 149.227 32.2414 149.956 32.2414C150.667 32.2414 151.218 32.0958 151.609 31.8044C152.018 31.5131 152.222 31.1398 152.222 30.6846C152.222 30.193 151.973 29.8288 151.475 29.5921C150.996 29.3372 150.222 29.0641 149.156 28.7728C148.054 28.4996 147.147 28.2174 146.436 27.9261C145.743 27.6347 145.139 27.1886 144.623 26.5878C144.126 25.9869 143.877 25.1766 143.877 24.1569C143.877 23.3194 144.108 22.5546 144.57 21.8627C145.05 21.1708 145.725 20.6245 146.596 20.2239C147.485 19.8234 148.525 19.6231 149.716 19.6231C151.475 19.6231 152.88 20.0783 153.928 20.9887C154.977 21.8809 155.555 23.0918 155.661 24.6213H152.089C152.035 24.0204 151.787 23.547 151.342 23.201C150.916 22.8368 150.338 22.6548 149.609 22.6548C148.934 22.6548 148.409 22.7822 148.036 23.0371C147.681 23.292 147.503 23.6471 147.503 24.1023C147.503 24.6122 147.752 25.0036 148.249 25.2768C148.747 25.5317 149.52 25.7957 150.569 26.0688C151.635 26.3419 152.515 26.6242 153.209 26.9155C153.902 27.2068 154.497 27.6621 154.995 28.2811C155.51 28.882 155.777 29.6832 155.795 30.6846C155.795 31.5586 155.555 32.3416 155.075 33.0335C154.613 33.7254 153.937 34.2717 153.049 34.6723C152.178 35.0546 151.156 35.2458 149.982 35.2458Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M172.356 27.1067C172.356 27.6529 172.321 28.1446 172.249 28.5816H161.451C161.54 29.6741 161.913 30.5299 162.571 31.1489C163.229 31.768 164.037 32.0776 164.997 32.0776C166.384 32.0776 167.37 31.4676 167.957 30.2476H171.983C171.556 31.7043 170.739 32.906 169.53 33.8529C168.321 34.7815 166.837 35.2458 165.077 35.2458C163.655 35.2458 162.375 34.9272 161.238 34.2899C160.118 33.6344 159.238 32.7149 158.598 31.5313C157.976 30.3478 157.665 28.9822 157.665 27.4344C157.665 25.8685 157.976 24.4938 158.598 23.3103C159.22 22.1267 160.091 21.2163 161.211 20.579C162.331 19.9417 163.62 19.6231 165.077 19.6231C166.481 19.6231 167.735 19.9326 168.837 20.5517C169.956 21.1708 170.819 22.0539 171.423 23.201C172.045 24.3299 172.356 25.6318 172.356 27.1067ZM168.49 26.0142C168.472 25.0309 168.126 24.248 167.45 23.6653C166.775 23.0644 165.948 22.764 164.971 22.764C164.046 22.764 163.264 23.0553 162.624 23.638C162.002 24.2025 161.62 24.9945 161.478 26.0142H168.49Z"
      fill="#000C21"
      className="letters"
    />
    <path
      d="M16.2094 12.5853C16.226 12.1503 16.564 11.827 16.7309 11.7198C16.6544 11.5922 16.025 11.5547 15.9206 11.0914C15.8162 10.6281 15.8892 10.316 15.983 9.4602C16.0768 8.6044 15.9238 8.34916 15.6978 8.40601C15.4717 8.46286 15.2457 8.51971 15.1484 8.93582C15.051 9.35193 14.8842 10.5198 14.7104 10.8083C14.5365 11.0968 13.528 10.8609 12.6412 10.1049C11.7543 9.34885 11.3404 7.93541 11.1803 6.80078C11.0202 5.66616 11.6391 4.38458 11.9102 3.67998C12.1814 2.97537 12.3761 2.14317 12.296 1.57585C12.216 1.00854 11.7639 1.12224 11.6144 1.3067C11.4649 1.49117 11.2216 2.53144 11.0999 3.05157C10.9783 3.57171 10.3594 4.85329 9.55259 4.66458C8.74579 4.47587 8.35263 2.5187 7.96651 1.44092C7.58039 0.363146 7.27783 0.292378 7.0518 0.349227C6.82577 0.406076 6.80496 0.949799 7.01723 2.31607C7.2295 3.68233 8.55465 5.74778 9.14247 6.87273C9.73028 7.99768 10.499 9.71354 10.4503 9.92159C10.4017 10.1296 10.1513 10.2905 9.67138 10.0686C9.19146 9.84658 8.78453 9.31253 8.45413 8.90609C8.12372 8.49965 7.61242 7.50232 7.25416 6.76021C6.8959 6.0181 6.64553 6.17898 6.54468 6.15539C6.44383 6.1318 6.26999 6.42029 6.27351 6.85999C6.27703 7.29969 7.19878 9.27077 7.35182 9.526C7.50485 9.78124 7.7866 10.3957 7.43538 10.533C7.08417 10.6703 6.50339 10.4247 6.42688 10.2971C6.35036 10.1695 5.56436 9.43707 5.4427 9.95721C5.32104 10.4773 5.7523 10.9074 6.40959 11.2805C7.06688 11.6537 7.6198 11.5636 9.02818 11.4542C10.4366 11.3447 11.6954 11.4197 12.93 11.5988C14.1645 11.7778 14.874 12.3826 15.3052 12.8126C15.7365 13.2427 15.9417 13.7295 16.1678 13.6727C16.3938 13.6159 16.1886 13.129 16.2094 12.5853Z"
      fill="#D0B262"
    />
    <path
      d="M21.5458 12.6779C21.5297 12.2585 21.2039 11.9469 21.043 11.8435C21.1167 11.7204 21.7236 11.6843 21.8242 11.2376C21.9248 10.7909 21.8544 10.4901 21.764 9.66497C21.6736 8.83988 21.8211 8.59381 22.039 8.64862C22.257 8.70343 22.4749 8.75823 22.5687 9.15941C22.6625 9.56059 22.8234 10.6866 22.991 10.9647C23.1586 11.2428 24.1309 11.0154 24.9859 10.2865C25.8409 9.55763 26.2401 8.1949 26.3944 7.10099C26.5487 6.00708 25.9521 4.77149 25.6906 4.09217C25.4292 3.41286 25.2415 2.61051 25.3187 2.06356C25.3959 1.5166 25.8317 1.62622 25.9758 1.80406C26.12 1.98191 26.3546 2.98485 26.4719 3.48632C26.5892 3.98779 27.1858 5.22338 27.9637 5.04144C28.7415 4.8595 29.1206 2.97257 29.4928 1.93347C29.8651 0.894366 30.1568 0.826136 30.3747 0.880945C30.5927 0.935754 30.6127 1.45997 30.4081 2.77721C30.2034 4.09445 28.9258 6.08577 28.3591 7.17036C27.7924 8.25494 27.0512 9.90922 27.0982 10.1098C27.1451 10.3104 27.3865 10.4655 27.8492 10.2515C28.3119 10.0375 28.7042 9.5226 29.0227 9.13075C29.3413 8.7389 29.8342 7.77735 30.1796 7.06187C30.525 6.3464 30.7664 6.5015 30.8637 6.47876C30.9609 6.45601 31.1285 6.73415 31.1251 7.15807C31.1217 7.58199 30.233 9.48235 30.0855 9.72842C29.9379 9.97449 29.6663 10.5669 30.0049 10.6993C30.3435 10.8317 30.9035 10.5949 30.9772 10.4719C31.051 10.3488 31.8088 9.64268 31.9261 10.1441C32.0434 10.6456 31.6276 11.0602 30.9939 11.42C30.3602 11.7798 29.8271 11.6929 28.4693 11.5874C27.1114 11.4819 25.8977 11.5542 24.7075 11.7268C23.5173 11.8994 22.8332 12.4825 22.4175 12.8971C22.0017 13.3117 21.8038 13.7811 21.5859 13.7263C21.368 13.6715 21.5658 13.2021 21.5458 12.6779Z"
      fill="#D0B262"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.9629 17.5284C20.2023 17.5284 21.207 16.5439 21.207 15.3295C21.207 14.1151 20.2023 13.1306 18.9629 13.1306C17.7235 13.1306 16.7188 14.1151 16.7188 15.3295C16.7188 16.5439 17.7235 17.5284 18.9629 17.5284Z"
      fill="#F10013"
    />
    <path
      d="M10.2109 15.8242C15.8502 15.8242 20.4217 20.3036 20.4217 25.8292V35.0645H14.1381V25.8292C14.1381 23.704 12.3799 21.9811 10.2109 21.9811V15.8242Z"
      fill="#895200"
    />
    <path
      d="M10.2107 15.8242C4.5715 15.8242 0 20.3036 0 25.8292V35.0645H6.28352V25.8292C6.28352 23.704 8.04179 21.9811 10.2107 21.9811V15.8242Z"
      fill="#B48A4B"
    />
  </svg>
);
