const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export function post(url, body = {}, headers = {}) {
  try {
    const options = {
      method: 'POST',
      credentials: 'same-origin',
      body: JSON.stringify(body),
      headers: { ...defaultHeaders, ...headers },
    };
    const absoluteUrl = getAbsoluteUrl(url);
    return fetch(absoluteUrl, options).then(res => res.json());
  } catch (error) {
    console.error(error);
  }
}

export function get(url, headers = {}) {
  try {
    const options = {
      credentials: 'same-origin',
      headers: { ...defaultHeaders, ...headers },
    };
    const absoluteUrl = getAbsoluteUrl(url);
    return fetch(absoluteUrl, options).then(res => res.json());
  } catch (error) {
    console.error(error);
  }
}

/**
 * @param {string} url - absolute or relative url
 * @return {string} absolute url, prefixed with rootUrl if ${url} was relative
 */
function getAbsoluteUrl(url) {
  const httpPrefix = 'http';
  if (url.startsWith(httpPrefix)) {
    return url;
  } else {
    // could use sharedConfig.rootUrl here to enforce absolute url
    // for now, leave it relative until we can fix the netlify headache
    return url;
  }
}
