const eventCategories = Object.freeze({
  link: 'link',
  button: 'button',
});

const eventActions = Object.freeze({
  click: 'click',
});

class TrackingEvent {
  constructor(category = eventCategories.link, action = eventActions.click) {
    this.category = category;
    this.action = action;
  }

  // for dynamically labeled events
  send(labelSuffix = '') {
    let label = this.label;
    if (labelSuffix) {
      label = `${label}:${labelSuffix.toLowerCase()}`;
    }

    if (window && window.analytics) {
      window.analytics.track(this.action, {
        category: this.category,
        label: label,
      });
    }
  }

  with(callback, labelSuffix) {
    return (...args) => {
      this.send(labelSuffix);
      callback(...args);
    };
  }
}

function mark(object, path = []) {
  if (object instanceof TrackingEvent) {
    object.label = path.join(':');
  } else {
    for (const key in object) {
      mark(object[key], [...path, key]);
    }
  }
  return object;
}

export const events = mark({
  navbar: {
    logo: new TrackingEvent(),
    link: new TrackingEvent(),
  },
  footer: {
    link: new TrackingEvent(),
  },
  dLinks: {
    succumbToCta: new TrackingEvent(),
  },
  referrals: {
    copyCode: new TrackingEvent(eventCategories.button, eventActions.click),
    tweetCode: new TrackingEvent(),
    tweetFact: new TrackingEvent(),
  }
});
