import React from 'react';
import { SocialIconWrapper } from './SocialIconWrapper';
import { theme } from '../../../theme';

export const CoinGeckoSocialIcon = props => (
  <SocialIconWrapper {...props} hoverColor={theme.color.coinGeckoGreen}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0F2E64" />
      <circle cx="19.433" cy="14.2699" r="1.41057" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3255 22.6814C12.5606 22.2112 13.0851 20.9453 13.3021 19.6432C13.4735 18.6145 13.5149 17.8242 13.5496 17.1627C13.5698 16.7776 13.5877 16.4362 13.6276 16.1168C13.7361 15.2488 13.8988 14.3265 14.4414 13.5127C14.9839 12.6989 16.1775 11.8851 16.8827 11.6138C17.588 11.3426 19.1071 10.9086 19.8124 11.0171C20.3766 11.1039 20.7709 11.234 20.8974 11.2882C21.2953 11.1797 22.2321 11.0277 22.7963 11.2882C23.3605 11.5487 23.6824 12.0478 23.7728 12.2648C24.677 12.5361 26.6482 13.1656 27.2993 13.5127C28.113 13.9466 28.8183 14.3264 29.0353 14.8689C29.0385 14.8769 29.0417 14.8849 29.0449 14.8928C29.2425 15.386 29.4268 15.8461 28.7764 17.0123C26.6747 18.453 24.5784 18.8284 23.2851 18.9362C22.2434 19.023 21.3682 18.6107 21.0607 18.3936C22.1024 19.2183 23.1585 19.3159 23.5564 19.2617C26.2041 18.9255 28.1159 17.6634 28.7598 17.0419C28.7115 17.1275 28.6588 17.2169 28.6013 17.3103C28.113 17.9613 26.8218 19.2308 25.9972 19.9686C24.9664 20.8909 23.9356 22.3558 23.5558 22.9525C23.1761 23.5493 22.6335 25.6109 22.6335 26.913V27.8353C21.6751 28.0342 19.2265 28.2151 17.0998 27.347C14.4414 26.262 12.9765 24.7971 12 23.5493C12.035 23.4228 12.149 23.0721 12.3255 22.6814ZM19.4327 16.3333C20.5713 16.3333 21.4943 15.4103 21.4943 14.2717C21.4943 13.1331 20.5713 12.2101 19.4327 12.2101C18.2941 12.2101 17.3711 13.1331 17.3711 14.2717C17.3711 15.4103 18.2941 16.3333 19.4327 16.3333ZM26.5937 16.6558C26.8334 16.6558 27.0278 16.4615 27.0278 16.2218C27.0278 15.9821 26.8334 15.7878 26.5937 15.7878C26.354 15.7878 26.1597 15.9821 26.1597 16.2218C26.1597 16.4615 26.354 16.6558 26.5937 16.6558Z"
        fill="white"
      />
    </svg>
  </SocialIconWrapper>
);
