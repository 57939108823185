import styled from 'styled-components';
import { theme } from '../theme';

// TODO: REPLACE THIS WITH <Button /> FROM NAMEBASEUI

export const buttonVariants = {
  primary: 'primary',
  secondary: 'secondary',
  tertiary: 'tertiary',
  destructive: 'destructive',
  white: 'white',
  nav: 'nav',
  textDark: 'textDark',
  proBrand: 'proBrand'
};

const buttonColors = {
  [buttonVariants.primary]: {
    initial: {
      color: theme.color.white,
      backgroundColor: theme.color.nbPrimary,
      borderColor: theme.color.nbPrimary,
    },
    hover: {
      color: theme.color.white,
      backgroundColor: theme.color.nbPrimaryDark,
      borderColor: theme.color.nbPrimary,
    },
  },
  [buttonVariants.secondary]: {
    initial: {
      color: theme.color.nbPrimary,
      backgroundColor: theme.color.white,
      borderColor: theme.color.nbPrimary,
    },
    hover: {
      color: theme.color.white,
      backgroundColor: theme.color.nbPrimary,
      borderColor: theme.color.nbPrimary,
    },
  },
  [buttonVariants.nav]: {
    initial: {
      color: theme.color.nbPrimary,
      backgroundColor: theme.color.white,
      borderColor: theme.color.white,
    },
    hover: {
      color: theme.color.nbPrimary,
      backgroundColor: theme.color.grey20,
      borderColor: theme.color.grey20,
    },
  },
  [buttonVariants.tertiary]: {
    initial: {
      color: theme.color.brandDark,
      backgroundColor: theme.color.white,
      borderColor: theme.color.grey30,
    },
    hover: {
      color: theme.color.nbPrimary,
      backgroundColor: theme.color.white,
      borderColor: theme.color.nbPrimary,
    },
  },
  [buttonVariants.destructive]: {
    initial: {
      color: theme.color.redDark,
      backgroundColor: theme.color.white,
      borderColor: theme.color.redDark,
    },
    hover: {
      color: theme.color.white,
      backgroundColor: theme.color.redDark,
      borderColor: theme.color.redDark,
    },
  },
  [buttonVariants.white]: {
    initial: {
      color: theme.color.nbPrimary,
      backgroundColor: theme.color.white,
      borderColor: theme.color.white,
    },
    hover: {
      color: theme.color.nbPrimary,
      backgroundColor: theme.color.grey10,
      borderColor: theme.color.grey10,
    },
  },
  [buttonVariants.textDark]: {
    initial: {
      color: theme.color.white,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
    hover: {
      color: theme.color.white,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    },
  },
  [buttonVariants.proBrand]: {
    initial: {
      color: theme.color.black,
      backgroundColor: theme.color.proBrand,
      borderColor: theme.color.proBrand,
    },
    hover: {
      color: theme.color.black,
      backgroundColor: theme.color.greenLight,
      borderColor: theme.color.greenLight,
    },
  },
};

export const Button = styled.button`
  height: ${props =>
    props.size
      ? props.theme.buttonSizes[props.size].height
      : props.theme.buttonSizes.medium.height};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0
    ${props =>
      props.size
        ? props.theme.buttonSizes[props.size].padding
        : props.theme.buttonSizes.medium.padding};
  width: ${props => props.fullWidth && '100%'};
  border-radius: ${props => props.theme.borderRadii.standard};
  border-width: 1px;
  border-style: solid;
  transition: ${props => props.theme.transitions.background},
    ${props => props.theme.transitions.color}, ${props => props.theme.transitions.border};
  font-weight: 500;
  font-size: ${props => props.theme.text.small.size};
  transition: opacity 0.2s ease;

  :disabled {
    opacity: 0.4;
  }

  color: ${({ variant = 'primary' }) => buttonColors[variant].initial.color};
  border-color: ${({ variant = 'primary' }) => buttonColors[variant].initial.borderColor};
  background-color: ${({ variant = 'primary' }) => buttonColors[variant].initial.backgroundColor};

  &:hover:not(disabled) {
    color: ${({ variant = 'primary' }) => buttonColors[variant].hover.color};
    border-color: ${({ variant = 'primary' }) => buttonColors[variant].hover.borderColor};
    background-color: ${({ variant = 'primary' }) => buttonColors[variant].hover.backgroundColor};
  }
`;
