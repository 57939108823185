import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { events } from '../../utils/trackEvent';
import { Huge } from '../text';
import { mobileNavItems } from './navItems';
import { Chevron } from '../../images/Chevron';
import { directions } from '../IconWrapper';
import { SecondaryMobileNavPanel } from './SecondaryMobileNavPanel';
import { setLastViewedWhatIsNew, navTypes, areUnreadNotifications } from './navItems';
import { navColors, navColorOptions } from './navColors';

const animationTimeInMs = 300;
const animationTime = `${(animationTimeInMs / 1000).toString()}s`;

export const OverflowManager = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 100%;
  overflow: ${props => props.hideOverflow && 'hidden'};
`;

export const MobileNavPanelWrapper = styled.nav`
  height: calc(100vh - 64px);
  width: 100vw;
  transform: ${props => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  transition-property: transform;
  transition-duration: ${props => props.closeDuration};
  transition-timing-function: cubic-bezier(0, 0, 0.125, 1);
  background: ${props =>
    props.navColor
      ? navColors[props.navColor].background
      : navColors[navColorOptions.default].background};
  position: absolute;
  left: 0;
  top: 64px;
  z-index: 7;
`;

const MobileNavLinkWrapper = styled.div`
  height: 56px;
  padding: 0 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  cursor: pointer;
  border-left: ${props => props.active && `4px solid ${props.theme.color.nbPrimary}`};
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: ${props =>
    props.active
      ? props.navColor
        ? navColors[props.navColor].activeBar
        : navColors[navColorOptions.default].activeBar
      : 'transparent'};
  ${Huge} {
    color: ${props =>
      props.navColor
        ? navColors[props.navColor].text.active
        : navColors[navColorOptions.default].text.active};
  }
  .chevron {
    fill: ${props =>
      props.navColor
        ? navColors[props.navColor].text.active
        : navColors[navColorOptions.default].text.active};
  }
`;

const Spacer = styled.div`
  height: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 24px;
`;

const Rule = styled.div`
  height: 2px;
  width: 40px;
  background: ${props => props.theme.color.borderGrey};
`;

const MobileNavLink = ({
  navItem,
  showSecondaryPanel,
  currentNavSection,
  shouldRenderComponent,
  user,
  loginRedirect,
  closeNav,
  navColor,
  news,
}) => {
  const { text, type, activeSection, showWhen, auxiliaryInfo } = navItem;
  const shouldRender = shouldRenderComponent(user, showWhen);
  if (!shouldRender) return null;
  const active = currentNavSection === activeSection;

  if (type === navTypes.LINK) {
    const { path, withState } = auxiliaryInfo;
    return (
      <Link
        to={withState ? { pathname: path, state: { from: loginRedirect } } : path}
        onClick={() => {
          events.navbar.link.send(text);
        }}>
        <MobileNavLinkWrapper active={active} navColor={navColor}>
          <Huge weight="medium">{text}</Huge>
        </MobileNavLinkWrapper>
      </Link>
    );
  } else if (type === navTypes.CATEGORY) {
    return (
      <MobileNavLinkWrapper
        navColor={navColor}
        onClick={() => showSecondaryPanel(auxiliaryInfo.dropdownInfo)}
        active={active}>
        <Huge weight="medium">{text}</Huge>
        <Chevron direction={directions.right} />
      </MobileNavLinkWrapper>
    );
  } else if (type === navTypes.NEWS) {
    const areUnread = areUnreadNotifications(news.map(item => item.date));
    return (
      <MobileNavLinkWrapper
        navColor={navColor}
        onClick={() => {
          showSecondaryPanel(news);
          if (user && user.email) {
            // only set last viewed if user is logged in
            setLastViewedWhatIsNew();
          }
        }}
        active={active}>
        <NewsText>
          {text}
          {areUnread ? <RedDot /> : null}
        </NewsText>
        <Chevron direction={directions.right} />
      </MobileNavLinkWrapper>
    );
  } else if (type === navTypes.CATEGORY_WITH_SECTIONS) {
    return (
      <MobileNavLinkWrapper
        navColor={navColor}
        onClick={() => showSecondaryPanel(auxiliaryInfo.dropdownInfo)}
        active={active}>
        <Huge weight="medium">{text}</Huge>
        <Chevron direction={directions.right} />
      </MobileNavLinkWrapper>
    );
  } else if (type === navTypes.ACTION) {
    return (
      <MobileNavLinkWrapper active={active} onClick={() => auxiliaryInfo.onClick(closeNav)} navColor={navColor}>
        <Huge weight="medium">{text}</Huge>
      </MobileNavLinkWrapper>
    );
  } else if (type === navTypes.TRADITIONAL_LINK) {
    const { path, newTab } = auxiliaryInfo;
    return (
      <a
        href={path}
        target={newTab ? '_blank' : null}
        rel={newTab ? 'noopener noreferrer' : null}
        onClick={() => {
          events.navbar.link.send(text);
        }}>
        <MobileNavLinkWrapper active={active} navColor={navColor}>
          <Huge weight="medium">{text}</Huge>
        </MobileNavLinkWrapper>
      </a>
    );
  } else if (type === navTypes.BUTTON) {
    return (
      <a
        href={auxiliaryInfo.path}
        rel="noopener noreferrer"
        onClick={() => {
          events.navbar.link.send(text);
        }}>
        <MobileNavLinkWrapper active={active} navColor={navColor}>
          <Huge weight="medium">{text}</Huge>
        </MobileNavLinkWrapper>
      </a>
    );
  }
};

const NewsText = styled(Huge).attrs(props => ({ weight: 'medium' }))`
  position: relative;
`;

const RedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${props => props.theme.color.red};
  position: absolute;
  top: 4px;
  transform: translateX(100%);
  right: -4px;
`;

export const MobileNavPanel = ({
  navColor,
  primaryPanelShowing,
  toggleHamburgerNav,
  currentNavSection,
  shouldRenderComponent,
  user,
  loginRedirect,
  news,
}) => {
  const [hideOverflow, setHideOverflow] = useState(true);
  const [secondaryPanelShowing, setSecondaryPanelShowing] = useState(false);
  const [hideSecondaryOverflow, setHideSecondaryOverflow] = useState(false);
  const [closeDuration, setCloseDuration] = useState(animationTime);
  const [secondaryPanelInfo, setSecondaryPanelInfo] = useState(null);

  const showSecondaryPanel = info => {
    setSecondaryPanelInfo(info);
    setSecondaryPanelShowing(true);
  };

  const hideSecondaryPanel = () => {
    setSecondaryPanelShowing(false);
  };

  useEffect(() => {
    if (primaryPanelShowing) {
      setHideOverflow(false);
    } else {
      setTimeout(() => setHideOverflow(true), animationTimeInMs);
    }
  }, [primaryPanelShowing]);

  useEffect(() => {
    if (secondaryPanelShowing) {
      setHideSecondaryOverflow(false);
    } else {
      setTimeout(() => setHideSecondaryOverflow(true), animationTimeInMs);
    }
  }, [secondaryPanelShowing]);

  const closeAll = () => {
    setCloseDuration('0');
    hideSecondaryPanel();
    toggleHamburgerNav();
    setTimeout(() => setCloseDuration(animationTime), animationTimeInMs);
  };

  return (
    <>
      <OverflowManager hideOverflow={hideOverflow}>
        <MobileNavPanelWrapper
          navColor={navColor}
          show={primaryPanelShowing}
          closeDuration={closeDuration}>
          {mobileNavItems.upperItems.map(navItem => (
            <MobileNavLink
              navColor={navColor}
              key={navItem.text}
              showSecondaryPanel={showSecondaryPanel}
              navItem={navItem}
              closeNav={closeAll}
              currentNavSection={currentNavSection}
              shouldRenderComponent={shouldRenderComponent}
              user={user}
            />
          ))}
          <Spacer>
            <Rule />
          </Spacer>
          {mobileNavItems.lowerItems.map(navItem => {
            return (
              <MobileNavLink
                navColor={navColor}
                key={navItem.text}
                navItem={navItem}
                toggleHamburgerNav={toggleHamburgerNav}
                closeNav={closeAll}
                showSecondaryPanel={showSecondaryPanel}
                currentNavSection={currentNavSection}
                shouldRenderComponent={shouldRenderComponent}
                user={user}
                loginRedirect={loginRedirect}
                news={news}
              />
            );
          })}
        </MobileNavPanelWrapper>
      </OverflowManager>
      <SecondaryMobileNavPanel
        navColor={navColor}
        hideSecondaryOverflow={hideSecondaryOverflow}
        hideSecondaryPanel={hideSecondaryPanel}
        secondaryPanelShowing={secondaryPanelShowing}
        primaryPanelShowing={primaryPanelShowing}
        closeAll={closeAll}
        closeDuration={closeDuration}
        secondaryPanelInfo={secondaryPanelInfo}
        MobileNavLink={MobileNavLink}
        currentNavSection={currentNavSection}
        shouldRenderComponent={shouldRenderComponent}
      />
    </>
  );
};
