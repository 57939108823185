import React from 'react';

import { SocialIconWrapper } from './SocialIconWrapper';
import { theme } from '../../../theme';

export const DiscordSocialIcon = props => (
  <SocialIconWrapper {...props} hoverColor={theme.color.discordPurple}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0F2E64" />
      <path
        d="M18.593 18.9727C18.0515 18.9727 17.624 19.4477 17.624 20.0272C17.624 20.6067 18.061 21.0817 18.593 21.0817C19.1345 21.0817 19.562 20.6067 19.562 20.0272C19.5715 19.4477 19.1345 18.9727 18.593 18.9727ZM22.0605 18.9727C21.519 18.9727 21.0915 19.4477 21.0915 20.0272C21.0915 20.6067 21.5285 21.0817 22.0605 21.0817C22.602 21.0817 23.0295 20.6067 23.0295 20.0272C23.0295 19.4477 22.602 18.9727 22.0605 18.9727Z"
        fill="white"
      />
      <path
        d="M26.6775 11H13.9475C12.874 11 12 11.874 12 12.957V25.801C12 26.884 12.874 27.758 13.9475 27.758H24.7205L24.217 26.0005L25.433 27.131L26.5825 28.195L28.625 30V12.957C28.625 11.874 27.751 11 26.6775 11ZM23.0105 23.407C23.0105 23.407 22.6685 22.9985 22.3835 22.6375C23.628 22.286 24.103 21.507 24.103 21.507C23.7135 21.7635 23.343 21.944 23.0105 22.0675C22.5355 22.267 22.0795 22.4 21.633 22.476C20.721 22.647 19.885 22.5995 19.1725 22.4665C18.631 22.362 18.1655 22.21 17.776 22.058C17.5575 21.9725 17.32 21.868 17.0825 21.735C17.054 21.716 17.0255 21.7065 16.997 21.6875C16.978 21.678 16.9685 21.6685 16.959 21.659C16.788 21.564 16.693 21.4975 16.693 21.4975C16.693 21.4975 17.149 22.2575 18.3555 22.6185C18.0705 22.9795 17.719 23.407 17.719 23.407C15.6195 23.3405 14.8215 21.963 14.8215 21.963C14.8215 18.904 16.1895 16.4245 16.1895 16.4245C17.5575 15.3985 18.859 15.427 18.859 15.427L18.954 15.541C17.244 16.035 16.4555 16.7855 16.4555 16.7855C16.4555 16.7855 16.6645 16.6715 17.016 16.51C18.0325 16.0635 18.84 15.94 19.1725 15.9115C19.2295 15.902 19.277 15.8925 19.334 15.8925C19.9135 15.8165 20.569 15.7975 21.253 15.8735C22.1555 15.978 23.1245 16.244 24.1125 16.7855C24.1125 16.7855 23.362 16.073 21.747 15.579L21.88 15.427C21.88 15.427 23.1815 15.3985 24.5495 16.4245C24.5495 16.4245 25.9175 18.904 25.9175 21.963C25.9175 21.963 25.11 23.3405 23.0105 23.407Z"
        fill="white"
      />
    </svg>
  </SocialIconWrapper>
);
