import React from 'react';
import styled from 'styled-components';
import { IconWrapper } from '../IconWrapper';

export const Avatar = () => {
  return (
    <IconWrapper>
      <svg
        width="20"
        height="19"
        viewBox="0 0 20 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <circle className="icon" cx="9.65863" cy="6.06097" r="5.18597" stroke="#091F43" strokeWidth="1.75" />
        <path
          d="M1 17.7502C4.03049 15.4413 10.9573 12.1222 18.3171 17.7502"
          stroke="#091F43"
          strokeWidth="1.75"
          strokeLinecap="round"
          className="icon"
        />
      </svg>
    </IconWrapper>
  );
};

const BellIconWrapper = styled(IconWrapper)`
  position: relative;
`;

const RedDot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background-color: ${props => props.theme.color.red};
  position: absolute;
  top: 1px;
  right: 0;
`;

export const Bell = ({ areUnread, onClick }) => {
  return (
    <BellIconWrapper onClick={onClick}>
      {areUnread ? <RedDot /> : null}
      <svg
        width="18"
        height="20"
        viewBox="0 0 18 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.75781 20C10.125 20 11.2187 18.9062 11.2187 17.5H6.25781C6.25781 18.9062 7.35156 20 8.75781 20Z"
          fill="#091F43"
          className="letters"
        />
        <path
          d="M16.5 15H1C1.83333 14.3333 3.5 12.3 3.5 9.5C3.5 6 4.5 4.5 6 3.5C6.68746 3.04169 7.58497 2.79343 8.5 2.75521C9.5815 2.71005 10.6875 2.95831 11.5 3.5C13 4.5 14 6.5 14 9.5C14 11.9 15.6667 14.1667 16.5 15Z"
          stroke="#091F43"
          strokeWidth="1.75"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="icon"
        />
        <path
          d="M9.875 1C9.875 0.516751 9.48325 0.125 9 0.125C8.51675 0.125 8.125 0.516751 8.125 1H9.875ZM9.875 2.5V1H8.125V2.5H9.875Z"
          fill="#091F43"
          className="letters"
        />
      </svg>
    </BellIconWrapper>
  );
};
