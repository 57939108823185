import styled, { css } from 'styled-components';

// just the weights our project uses TODO maybe put into theme
const textWeights = {
  light: '300',
  regular: '400',
  medium: '500',
  bold: '700',
};

const textAlignment = {
  left: 'left',
  right: 'right',
  center: 'center',
  justify: 'justify',
  initial: 'initial',
  inherit: 'inherit',
};

const textStyles = {
  normal: 'normal',
  italic: 'italic',
  // oblique: 'oblique', NOTE: bad practice to use, but leaving commented to show that it wasn't overlooked
};

const textTransformations = {
  uppercase: 'uppercase',
  lowercase: 'lowercase',
  capitalize: 'capitalize',
};

const Text = props => {
  const color = props.colored ? props.theme.color[props.colored] : props.theme.color.darkText;
  const weight = props.weight && textWeights[props.weight];
  const alignment = props.align && textAlignment[props.align];
  const transform = props.transform && textTransformations[props.transform];
  const style = props.styled && textStyles[props.styled];

  return css`
    color: ${color};
    font-weight: ${weight};
    text-align: ${alignment};
    text-transform: ${transform};
    font-style: ${style};
  `;
};

// TODO automatically generate this
export const DisplayLarge = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.displayLarge.size};
  line-height: ${props => props.theme.text.displayLarge.lineHeight};
`;

export const Display = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.display.size};
  line-height: ${props => props.theme.text.display.lineHeight};
`;

export const DisplaySmall = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.displaySmall.size};
  line-height: ${props => props.theme.text.displaySmall.lineHeight};
`;

export const DisplayTiny = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.displayTiny.size};
  line-height: ${props => props.theme.text.displayTiny.lineHeight};
`;

export const HeaderLarge = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.headerLarge.size};
  line-height: ${props => props.theme.text.headerLarge.lineHeight};
`;

export const Header = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.header.size};
  line-height: ${props => props.theme.text.header.lineHeight};
`;

export const HeaderSmall = styled.h1`
  ${Text};
  font-size: ${props => props.theme.text.headerSmall.size};
  line-height: ${props => props.theme.text.headerSmall.lineHeight};
`;

export const Huge = styled.h2`
  ${Text};
  font-size: ${props => props.theme.text.huge.size};
  line-height: ${props => props.theme.text.huge.lineHeight};
`;

export const Large = styled.div`
  ${Text};
  font-size: ${props => props.theme.text.large.size};
  line-height: ${props => props.theme.text.large.lineHeight};
`;

export const Medium = styled.div`
  ${Text};
  font-size: ${props => props.theme.text.medium.size};
  line-height: ${props => props.theme.text.medium.lineHeight};
`;

export const Regular = styled.div`
  ${Text};
  font-size: ${props => props.theme.text.regular.size};
  line-height: ${props => props.theme.text.regular.lineHeight};
`;

export const Small = styled.div`
  ${Text};
  font-size: ${props => props.theme.text.small.size};
  line-height: ${props => props.theme.text.small.lineHeight};
`;

export const Tiny = styled.div`
  ${Text};
  font-size: ${props => props.theme.text.tiny.size};
  line-height: ${props => props.theme.text.tiny.lineHeight};
`;
