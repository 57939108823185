import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Hamburger } from './Hamburger';
import { BackArrow } from '../../images/BackArrow';
import { Medium, Regular } from '../text';
import { navColors, navColorOptions } from './navColors';
import { formatDate } from './navItems';
import { renderNewsWithLinks } from './NewsPopout';

// TODO: make these come from one file and don't basically duplicate them in both panels
export const MobileNavPanelWrapper = styled.nav`
  height: 100vh;
  width: 100vw;
  transform: ${props => (props.show ? 'translateX(0)' : 'translateX(100%)')};
  transition-property: transform;
  transition-duration: ${props => props.closeDuration};
  transition-timing-function: cubic-bezier(0, 0, 0.125, 1);
  background: ${props =>
    props.navColor
      ? navColors[props.navColor].background
      : navColors[navColorOptions.default].background};
  position: absolute;
  left: 0;
  top: 0;
  z-index: 25;
  overflow: auto;

  & > :last-child {
    display: block;
    padding-bottom: ${({ theme }) => theme.spacing(16)};
  }
`;

const OverflowManager = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  width: 100%;
  overflow: ${props => props.hideOverflow && 'hidden'};
`;

const TopBar = styled.div`
  height: 64px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  margin-bottom: 16px;
  .line {
    fill: ${props =>
      props.navColor ? navColors[props.navColor].logo : navColors[navColorOptions.default].logo};
  }
  .arrow {
    stroke: ${props =>
      props.navColor ? navColors[props.navColor].logo : navColors[navColorOptions.default].logo};
  }
`;

const MobileNavLinkWrapper = styled.div`
  height: 56px;
  padding: 0 24px;
  display: flex;
  align-items: center;
  borer-left: 2px solid white;
  margin-top: 16px;
`;

export const SecondaryMobileNavPanel = ({
  navColor,
  hideSecondaryOverflow,
  hideSecondaryPanel,
  secondaryPanelShowing,
  primaryPanelShowing,
  closeAll,
  closeDuration,
  secondaryPanelInfo,
  MobileNavLink,
  currentNavSection,
  shouldRenderComponent,
}) => {
  return (
    <OverflowManager hideOverflow={hideSecondaryOverflow}>
      <MobileNavPanelWrapper
        navColor={navColor}
        show={secondaryPanelShowing}
        closeDuration={closeDuration}>
        <TopBar navColor={navColor}>
          <BackArrow onClick={hideSecondaryPanel} />
          <Hamburger open={primaryPanelShowing} toggle={closeAll} />
        </TopBar>
        {secondaryPanelInfo && secondaryPanelInfo[0].news ? (
          <WhatNewBar>What's new</WhatNewBar>
        ) : null}
        {secondaryPanelInfo &&
          secondaryPanelInfo.map(info => {
            if (info.news) {
              const { title, date, news: newsText } = info;
              return <NewsComponent key={title} date={date} title={title} news={newsText.news} />;
            } else {
              if (info.title) {
                return (
                  <React.Fragment key={info.title}>
                    <MobileNavLinkWrapper>
                      <Regular key={info.title} weight="bold" colored="grey70">
                        {info.title}
                      </Regular>
                    </MobileNavLinkWrapper>
                    {info.items.map(info => (
                      <MobileNavLink
                        navColor={navColor}
                        key={info.text}
                        navItem={info}
                        closeNav={closeAll}
                        currentNavSection={currentNavSection}
                        shouldRenderComponent={shouldRenderComponent}
                      />
                    ))}
                  </React.Fragment>
                );
              }
              return (
                <MobileNavLink
                  navColor={navColor}
                  key={info.text}
                  navItem={info}
                  closeNav={closeAll}
                  currentNavSection={currentNavSection}
                  shouldRenderComponent={shouldRenderComponent}
                />
              );
            }
          })}
      </MobileNavPanelWrapper>
    </OverflowManager>
  );
};

const WhatNewBar = styled(Regular).attrs(props => ({ colored: 'nbPrimary', weight: 'bold' }))`
  width: 100%;
  padding: 0 ${props => props.theme.spacing(3)};
  height: ${props => props.theme.spacing(7)};
  line-height: ${props => props.theme.spacing(7)};
  margin-top: ${props => props.theme.spacing(2)};
`;

const NewsComponentWrapper = styled.div`
  padding: ${props => props.theme.spacing(3)} 0;
  margin: 0 ${props => props.theme.spacing(3)};
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.color.nbPrimary};
  }
`;

const Date = styled.time`
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${props => props.theme.color.grey70};
`;

const NewsTitle = styled(Medium).attrs(props => ({ weight: 'bold' }))``;

const NewsInfo = styled.div`
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${props => props.theme.color.grey70};
  white-space: normal;
`;

const NewsComponent = ({ date, title, news }) => {
  return (
    <NewsComponentWrapper>
      <Date>{formatDate(date)}</Date>
      <NewsTitle>{title}</NewsTitle>
      <NewsInfo>{renderNewsWithLinks(news)}</NewsInfo>
    </NewsComponentWrapper>
  );
};

SecondaryMobileNavPanel.propTypes = {
  hideSecondaryPanel: PropTypes.func.isRequired,
  closeAll: PropTypes.func.isRequired,
  closeDuration: PropTypes.string.isRequired,
  primaryPanelShowing: PropTypes.bool.isRequired,
  hideSecondaryOverflow: PropTypes.bool.isRequired,
  secondaryPanelShowing: PropTypes.bool.isRequired,
  secondaryPanelInfo: PropTypes.array,
};
