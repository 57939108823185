import { ThemeProvider } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import React from 'react';

import { Footer } from './footer';
import { Navbar, navSections } from './navbar';
import { theme } from '../theme';
import { useUser } from '../utils/useUser';
import { AnnouncementBanner } from './AnnouncementBanner';
import { renderNewsWithLinks } from './navbar/NewsPopout';

import './layout.css';

const ANNOUNCEMENT_LOCAL_STORAGE_KEY = 'namebase:announcement:dismissed';

const WhatsNewQuery = graphql`
  query WhatsNewQuery {
    allContentfulWhatIsNew {
      edges {
        node {
          date
          createdAt
          title
          news {
            news
          }
        }
      }
    }
    allContentfulGlobalAnnouncement(sort: { order: DESC, fields: createdAt }, limit: 1) {
      nodes {
        contentful_id
        announcement
        expiryDate
        link
        debugValue
      }
    }
  }
`;

const Layout = ({
  children,
  currentSection,
  noFooter,
  navColor,
  navbarRef,
  bannerRef,
  minimalNavbar = false,
  onScrolledToTopChange,
  onAnnouncementDismiss,
  includeNavigationBar = true,
}) => {
  const topOfPageInView = useScrollToTop();

  const { allContentfulWhatIsNew, allContentfulGlobalAnnouncement } = useStaticQuery(WhatsNewQuery);
  const globalAnnouncement = allContentfulGlobalAnnouncement.nodes[0];
  const [shouldShowBanner, setShouldShowBanner] = React.useState(false);

  const user = useUser();

  React.useEffect(() => {
    if (typeof window === 'undefined') return null;
    if (window.analytics) window.intercomSettings = { hide_default_launcher: true };
  }, []);

  React.useEffect(() => {
    if (onScrolledToTopChange) onScrolledToTopChange(topOfPageInView);
  }, [onScrolledToTopChange, topOfPageInView]);

  const onBannerDismiss = () => {
    if (typeof window === 'undefined') return null;

    // Only passed in by index (landing) page
    if (onAnnouncementDismiss) onAnnouncementDismiss();

    window.localStorage.setItem(
      ANNOUNCEMENT_LOCAL_STORAGE_KEY,
      JSON.stringify({ dismissed: true, id: globalAnnouncement.contentful_id })
    );
  };

  React.useEffect(() => {
    let shouldShow = false;

    const { contentful_id, debugValue, expiryDate } = globalAnnouncement;

    const currentDate = Math.floor(Date.now() / 1000);
    if (typeof window === 'undefined' || debugValue) {
      return setShouldShowBanner(false);
    }

    const isExpired = currentDate > expiryDate;
    const stored = window.localStorage.getItem(ANNOUNCEMENT_LOCAL_STORAGE_KEY);

    if (!stored && !isExpired) {
      shouldShow = true;
    } else if (isExpired) {
      shouldShow = false;
    } else {
      const parseStored = JSON.parse(stored);

      if (contentful_id !== parseStored.id) {
        shouldShow = true;
      } else if (!parseStored.dismissed) {
        shouldShow = true;
      } else {
        shouldShow = false;
      }
    }

    setShouldShowBanner(shouldShow);
  }, [globalAnnouncement]);

  return (
    <ThemeProvider theme={theme}>
      <React.Fragment>
        {includeNavigationBar ? (
          <Navbar
            ref={navbarRef}
            logoOnly={minimalNavbar}
            pageHasScrolled={!topOfPageInView}
            currentNavSection={currentSection || navSections.SPLASH}
            user={user}
            navColor={navColor}
            news={allContentfulWhatIsNew.edges
              .map(edge => edge.node)
              .sort((a, b) => {
                return a.createdAt < b.createdAt;
              })}>
            <AnnouncementBanner
              ref={bannerRef}
              show={shouldShowBanner}
              onDismiss={onBannerDismiss}
              title={globalAnnouncement.announcement}
              link={renderNewsWithLinks(globalAnnouncement.link, <Arrow />)}
            />
          </Navbar>
        ) : null}

        <main css={{ display: 'initial' }}>
          {children}
        </main>

        {noFooter ? null : <Footer />}
      </React.Fragment>
    </ThemeProvider>
  );
};

const Arrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M14 16L18 12L14 8" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M17.1359 12.0281L5.75109 12.0281"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

// NOTE: The other option was to use an IntersectionObserver
// at the top of the page. After performance profiling both
// options, I discovered that the internal intersection observer
// callbacks could be very expensive (50ms or greater). This caused
// noticeable frame drops especically when combined with the
// animation that runs at the top of the page.
//
// On the other hand the onScroll callback here is much quicker
// (< 4ms) and does not result in frame drops.
const useScrollToTop = function() {
  const [scrolledToTop, setScrolledToTop] = React.useState(true);

  const onScroll = React.useCallback(() => {
    if (window.scrollY === 0) {
      setScrolledToTop(true);
    } else if (window.scrollY !== 0 && scrolledToTop) {
      setScrolledToTop(false);
    }
  }, [scrolledToTop]);

  React.useEffect(() => {
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, [onScroll]);

  return scrolledToTop;
};

export default Layout;
