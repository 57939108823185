import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { AccessiblyHide } from '../AccessiblyHide';
import { IconWrapper } from '../IconWrapper';

const Bun = styled.svg`
  cursor: pointer;
  width: 18px;
  height: 14px;
  position: relative;

  & rect:nth-child(1) {
    transform: ${props => (props.open ? 'translateX(4px) rotate(45deg)' : null)};
    transform-origin: top left;
  }
  & rect:nth-child(3) {
    transform: ${props => (props.open ? 'translateX(4px) rotate(-45deg)' : null)};
    transform-origin: bottom left;
  }
  & rect:nth-child(2) {
    opacity: ${props => (props.open ? '0' : '1')};
  }
`;

const Line = styled.rect.attrs(props => ({ className: 'line' }))`
  width: 18px;
  height: 2px;
  fill: ${props => props.theme.color.darkText};
  transition: transform 0.2s ease-in-out, opacity 0.12s ease-in-out;
  position: relative;
`;

const HamburgerWrapper = styled(IconWrapper)`
  display: none;
  position: relative;

  @media ${({ theme, breakAt }) => theme.breakpoints.mdForNavbar} {
    display: initial;
  }
`;

const Ketchup = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 100%;
  position: absolute;
  top: 0;
  right: 0;
  background-color: ${props => props.theme.color.red};
  transform: translateX(100%);
`;

const Takeaway = styled.button`
  border: none;
  padding: ${({ theme }) => theme.spacing(1.5)};
  background: none;
`;

export const Hamburger = ({ areUnread, open, toggle }) => (
  <Takeaway>
    <HamburgerWrapper>
      {areUnread ? <Ketchup /> : null}
      <Bun viewBox="0 0 18 14" xmlns="http://www.w3.org/2000/svg" open={open} onClick={toggle}>
        <Line rx="1" />
        <Line y="6" rx="1" />
        <Line y="12" rx="1" />
      </Bun>
    </HamburgerWrapper>
  </Takeaway>
);

Hamburger.propTypes = {
  open: PropTypes.bool,
  toggle: PropTypes.func,
};
