import React, { useState, useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { NamebaseLogo, NamebaseChristmasLogo } from '../../images/NamebaseLogo';
import { events } from '../../utils/trackEvent';
import { Hamburger } from './Hamburger';
import { AccessiblyHide } from '../AccessiblyHide';
import { disableScroll, reEnableScroll } from '../disableScroll';
import {
  navSections,
  actions,
  shouldRenderComponent,
  desktopNavItems,
  areUnreadNotifications,
} from './navItems';
import { MobileNavPanel } from './MobileNavPanel';
import { NavLinkComponent } from './NavLinkComponent';
import { navColors, navColorOptions } from './navColors';
import moment from 'moment';

const DesktopWrapper = styled.div`
  width: 100%;
  height: ${props => props.theme.spacing(10)};
  background: ${props =>
    props.navColor
      ? navColors[props.navColor].background
      : navColors[navColorOptions.default].background};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: ${props => props.scrolled && props.theme.shadows.navbar};
  transition: ${props => props.theme.transitions.shadow};
  padding: 0px 48px 0px 32px;
  .line {
    fill: ${props =>
      props.navColor ? navColors[props.navColor].logo : navColors[navColorOptions.default].logo};
  }
  .letters {
    fill: ${props =>
      props.navColor ? navColors[props.navColor].logo : navColors[navColorOptions.default].logo};
  }
  .icon {
    stroke: ${props =>
      props.navColor ? navColors[props.navColor].logo : navColors[navColorOptions.default].logo};
  }
  @media ${props => props.theme.breakpoints.mdForNavbar} {
    padding: 0 ${props => props.theme.spacing(3)};
    height: ${props => props.theme.spacing(8)};
  }
`;

const LogoWrapper = styled.a`
  width: ${props => props.theme.spacing(22)};
  height: 24px;

  svg {
    width: 100%;
  }

  @media ${props => props.theme.breakpoints.mdForNavbar} {
    width: ${props => props.theme.spacing(16)};
  }
`;

const RightWrapper = styled.nav`
  display: flex;
  padding-top: 6px;

  @media ${props => props.theme.breakpoints.mdForNavbar} {
    display: none;
  }
`;

const NavLinks = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const NavRootHeader = styled.header`
  position: ${({ position }) => position};
  top: 0;
  z-index: 2;
`;

const noHoveredNavItems = new Array(desktopNavItems.length).fill(false);

const setHoveredNavItems = (state, action) => {
  switch (action.type) {
    case actions.entered:
      const nextState = [...state];
      nextState[action.itemIndex] = true;
      return nextState;
    case actions.left:
      return noHoveredNavItems;
    default:
      throw new Error();
  }
};

const Navbar = React.forwardRef(
  (
    {
      news,
      pageHasScrolled,
      currentNavSection,
      user,
      loginRedirect,
      navColor,
      children,
      logoOnly = false,
    },
    ref
  ) => {
    const [scrollDisabled, setScrollDisabled] = useState(false);
    const [menuOpen, setMenuOpen] = useState(false);
    const [hoveredNavItems, dispatchMouseAction] = useReducer(
      setHoveredNavItems,
      noHoveredNavItems
    );

    const toggleHamburgerNav = () => {
      setMenuOpen(previousMenuOpen => !previousMenuOpen);
      if (scrollDisabled) {
        reEnableScroll();
        setScrollDisabled(false);
      } else {
        disableScroll();
        setScrollDisabled(true);
      }
    };

    useEffect(() => {
      return () => {
        if (scrollDisabled) reEnableScroll();
      };
    });

    const navItemHovered = hoveredNavItems.includes(true);

    const areUnread =
      user && user.email ? areUnreadNotifications(news.map(item => item.date)) : false;

    return (
      <NavRootHeader ref={ref} position={logoOnly ? 'static' : 'sticky'}>
        {children}
        <DesktopWrapper scrolled={logoOnly ? false : pageHasScrolled} navColor={navColor}>
          <LogoWrapper href="/">
            {moment().isBetween(
              new Date(new Date().getFullYear(), 11, 10),
              new Date(new Date().getFullYear(), 11, 31)
            ) ? (
              // Rudolfs horns make the christmas logo approx 12% larger than the normal logo,
              // so we to offset so the two appear in the exact same position
              <div css={{ transform: 'translateY(-12%)' }}>
                <NamebaseChristmasLogo />
              </div>
            ) : (
              <NamebaseLogo />
            )}
          </LogoWrapper>
          {!logoOnly ? (
            <React.Fragment>
              <Hamburger areUnread={areUnread} open={menuOpen} toggle={toggleHamburgerNav} />
              <RightWrapper>
                <NavLinks>
                  {desktopNavItems.map((navItem, index) => (
                    <NavLinkComponent
                      news={news}
                      navColor={navColor}
                      key={navItem.text}
                      navItem={navItem}
                      dispatchMouseAction={dispatchMouseAction}
                      index={index}
                      navItemHovered={navItemHovered}
                      hoveredNavItems={hoveredNavItems}
                      currentNavSection={currentNavSection}
                      user={user}
                      actions={actions}
                      shouldRenderComponent={shouldRenderComponent}
                      loginRedirect={loginRedirect}
                    />
                  ))}
                </NavLinks>
              </RightWrapper>
            </React.Fragment>
          ) : null}
        </DesktopWrapper>
        <MobileNavPanel
          navColor={navColor}
          primaryPanelShowing={menuOpen}
          toggleHamburgerNav={toggleHamburgerNav}
          currentNavSection={currentNavSection}
          shouldRenderComponent={shouldRenderComponent}
          user={user}
          loginRedirect={loginRedirect}
          news={news}
        />
      </NavRootHeader>
    );
  }
);

Navbar.propTypes = {
  currentNavSection: PropTypes.string.isRequired,
  position: PropTypes.string,
};

export { Navbar };
export { navSections };
