const unit = 8;

const liz = {
  color: {
    white: '#FFFFFF',
    nbPrimaryLight: '#007FFF',
    nbPrimary: '#0068FF',
    nbPrimaryDark: '#0056d7',
    blue40: '#0063f2',
    blue60: '#0049B6',
    darkText: '#091F43',
    greyText: '#8894A8',
    grey90: '#545E6F',
    grey80: '#657287',
    grey70: '#738198',
    grey60: '#8894A8',
    grey50: '#A4AEBD',
    grey40: '#B9C1CE',
    grey30: '#D4DAE2',
    grey20: '#EEF0F5',
    grey10: '#F8F9FB',
    altDarkGrey: '#404040',
    red: '#f10013',
    redDark: '#e61a2f',
    borderGrey: '#E1E4E8',
    placeholderText: '#a4aebd',
    namebaseGreen: '#00CD8F',
    greenLight: '#28EAC2',
    yellowLight: '#FFC73A',
    yellowDark: '#FFA800',
    // TODO add that other color liz added
    grey10: '#F8F9FB',
    proBrand: '#49FBD4',
    proBrandDark: '#2FA78C',
    blueDark: '#1E2632',
    blue90: '#071834',
    // TODO this is the same as the dark text color
    brandDark: '#091F43',
    coinGeckoGreen: '#8DC647',
    twitterBlue: '#1DA1F2',
    discordPurple: '#7289DA',
    nomicsPurple: '#9E37F6',

    nbBlue900: '#0A44A5',
    nbBlue800: '#0D55CF',
    nbBlue700: '#0C66FF',
    nbBlue600: '#0081FF',
    nbBlue500: '#5AACFF',
    nbBlue400: '#99CCFF',
    nbBlue300: '#CCE6FF',
    nbBlue200: '#E8F5FF',
    nbBlue100: '#F4FAFF',
  },
  text: {
    displayLarge: { size: '48px', lineHeight: '56px' },
    display: { size: '46px', lineHeight: '56px' },
    displaySmall: { size: '42px', lineHeight: '52px' },
    displayTiny: { size: '40px', lineHeight: '48px' },
    headerLarge: { size: '30px', lineHeight: '40px' },
    header: { size: '28px', lineHeight: '36px' },
    headerSmall: { size: '26px', lineHeight: '34px' },
    huge: { size: '24px', lineHeight: '34px' },
    large: { size: '20px', lineHeight: '32px' },
    medium: { size: '18px', lineHeight: '28px' },
    regular: { size: '16px', lineHeight: '24px' },
    small: { size: '14px', lineHeight: '24px' },
    tiny: { size: '12px', lineHeight: '16px' },
  },
  textWeight: {
    light: "'wght' 300",
    regular: "'wght' 400",
    medium: "'wght' 500",
    bold: "'wght' 700",
  },
  shadows: {
    navbar: '0px 4px 10px rgba(0, 0, 0, 0.07)',
    dropdown: '0px 0px 9px rgba(0, 0, 0, 0.17)',
  },
  get unit() {
    return 8;
  },
  spacing: multiple => (multiple * 8).toString() + 'px',
  get buttonSizes() {
    return {
      tiny: { height: this.spacing(3), padding: this.spacing(1), fontSize: '12px' },
      small: { height: this.spacing(4), padding: this.spacing(2), fontSize: '14px' },
      medium: { height: this.spacing(5), padding: this.spacing(3), fontSize: '14px' },
      large: { height: this.spacing(6), padding: this.spacing(4), fontSize: '14px' },
    };
  },
  borderRadii: {
    standard: '4px',
    curvy: '8px',
  },
  transitions: {
    color: 'color 0.2s ease',
    stroke: 'stroke 0.2s ease',
    background: 'background-color 0.2s ease',
    transform: 'transform 0.2s ease',
    border: 'border-color 0.2s ease',
    fill: 'fill 0.2s ease',
    stroke: 'stroke 0.2s ease',
    shadow: 'box-shadow 0.2s ease-in-out',
  },
  get breakpoints() {
    return {
      lg: `(min-width: ${this.unit * 110}px)`,
      mdForNavbar: `(max-width: calc(${this.unit * 125}px - 1px))`,
      md: `(max-width: calc(${this.unit * 110}px - 1px))`,
      sm: `(max-width: calc(${this.unit * 96}px - 1px))`,
      xs: `(max-width: calc(${this.unit * 71}px - 1px))`,
      smMin: `(min-width: ${this.unit * 96}px)`,
      xsMin: `(min-width: ${this.unit * 71}px)`,
    };
  },
  grid: {
    columnGap: '8px',
    numberColumns: 12,
    sizes: {
      xs: {
        maxWidth: '375px',
        gutterWidth: '16px',
      },
      sm: {
        maxWidth: '568px',
        gutterWidth: '24px',
      },
      md: {
        maxWidth: '768px',
        gutterWidth: '48px',
      },
      lg: {
        maxWidth: '880px',
        gutterWidth: '48px',
      },
    },
  },
  gradients: {
    redOrange: 'linear-gradient(175.91deg, #FF5980 1.46%, #FFA882 98.22%)',
    grellow: 'linear-gradient(111.8deg, #49FBD4 28.57%, #FFC73A 85.72%)',
    greenGreen: 'linear-gradient(341.94deg, #5DFDBE 11.16%, #7AF9EA 88.22%)',
  },
};

export { liz };
