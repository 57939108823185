import React from 'react';
import { IconWrapper } from '../components/IconWrapper';

export const BackArrow = ({ onClick }) => (
  <IconWrapper onClick={onClick}>
    <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="arrow"
        d="M8.3242 2.00002L2 8.32422L8.3242 14.6484"
        stroke="#022047"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="arrow"
        d="M3.36816 8.27881H21.3682"
        stroke="#022047"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </IconWrapper>
);
