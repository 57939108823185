import React from 'react';
import styled from 'styled-components';
import { PopoutSpacer, PopoutTriangle, PopoutBody, NavbarPopoutLink } from './NavbarPopout';

const RightAnchoredPopoutBody = styled(PopoutBody)`
  top: calc(100% - 8px);
  left: initial;
  right: ${({ theme }) => theme.spacing(2)};
`;

export const RightAnchoredPopout = ({ dropdownInfo, show }) => {
  const contentRef = React.useRef(null);
  const [triangleOffset, setTriangleOffset] = React.useState(0);

  React.useLayoutEffect(() => {
    if (show && contentRef && contentRef.current) {
      const { x, width } = contentRef.current.parentElement.getBoundingClientRect();
      setTriangleOffset(x + width / 2);
    }
  }, [show]);

  if (!show) return null;
  return (
    <React.Fragment>
      <PopoutSpacer />
      <PopoutTriangle style={{ top: "calc(100% - 16px)", left: `${triangleOffset - 8}px` }}/>
      <RightAnchoredPopoutBody ref={contentRef}>
        {dropdownInfo.map(info => (
          <NavbarPopoutLink key={info.text} dropdownInfo={info} />
        ))}
      </RightAnchoredPopoutBody>
    </React.Fragment>
  );
};
