import { theme } from '../../theme';
import { buttonVariants } from '../../components/Button';

export const navColorOptions = Object.freeze({
  void: 'void',
  burnt: 'burnt',
  default: 'default',
  dramatic: 'dramatic',
  nbPrimary: 'nbPrimary',
});

const { color } = theme;

export const navColors = {
  [navColorOptions.default]: {
    logo: color.black,
    text: {
      active: color.darkText,
      inactive: color.grey60,
    },
    activeBar: color.nbPrimary,
    background: color.white,
    buttonType: buttonVariants.standard,
  },
  [navColorOptions.dramatic]: {
    logo: color.white,
    text: {
      active: color.white,
      inactive: color.grey30,
    },
    activeBar: color.white,
    background: color.almostBlack,
    buttonType: buttonVariants.textDark,
  },
  [navColorOptions.burnt]: {
    logo: color.white,
    text: {
      active: color.white,
      inactive: color.grey30,
    },
    activeBar: color.white,
    background: color.burntUmber,
    buttonType: buttonVariants.standard,
  },
  [navColorOptions.nbPrimary]: {
    logo: color.white,
    text: {
      active: color.white,
      inactive: color.grey30,
    },
    activeBar: color.white,
    background: color.nbPrimary,
    buttonType: buttonVariants.nav,
  },
  [navColorOptions.void]: {
    logo: color.white,
    text: {
      active: color.white,
      inactive: color.grey30,
    },
    activeBar: color.white,
    background: color.dmGrey10,
    buttonType: buttonVariants.proBrand,
  },
};
