import styled from 'styled-components';

export const directions = {
  right: 'right',
  left: 'left',
  up: 'up',
  down: 'down',
};

const rotations = {
  [directions.right]: 'rotate(-0.25turn)',
  [directions.left]: 'rotate(0.25turn)',
  [directions.up]: 'rotate(0.5turn)',
  [directions.down]: 'rotate(0turn)',
};

// TODO make this wrapper better for customization, esp. once we start using a large icon.
export const IconWrapper = styled.div`
  width: ${props => (props.medium ? props.theme.spacing(4) : props.theme.spacing(3))};
  height: ${props => (props.medium ? props.theme.spacing(4) : props.theme.spacing(3))};
  display: flex;
  /* so if the icon is in a flex container it's wrapper is still the same size: */
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  transform: ${props => props.direction && rotations[props.direction]};
`;

// TODO make an absraction like this later that also allows for extending the icon wrapper
// export const Icon = ({ icon }) => <IconWrapper>{icon}</IconWrapper>;
