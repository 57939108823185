import styled from 'styled-components';

export const SocialIconWrapper = styled.a`
  &:hover {
    svg {
      path {
        fill: ${({ hoverColor }) => hoverColor};
      }
      circle:nth-of-type(2) {
        fill: ${({ hoverColor }) => hoverColor};
      }
    }
  }
`;
