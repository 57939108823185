import { Avatar, Bell } from './navIcons';
import {
  learnUrl,
  aboutHandshakeLearnUrl,
  showcaseUrl,
  communityUrl,
  newsUrl,
  discordUrl,
  supportUrl,
  developersUrl,
  primerUrl,
} from '../../config';
import { post } from '../../utils/requestFunctions';

// for determining when to show a component
const showStates = {
  ALWAYS: 'ALWAYS',
  LOGGEDIN: 'LOGGEDIN',
  LOGGEDOUT: 'LOGGEDOUT',
};

// types of nav items that can exist
export const navTypes = {
  NEWS: 'NEWS',
  LINK: 'LINK',
  BUTTON: 'BUTTON',
  ACTION: 'ACTION',
  CATEGORY: 'CATEGORY',
  TRADITIONAL_LINK: 'TRADITIONAL_LINK',
  CATEGORY_WITH_SECTIONS: 'CATEGORY_WITH_SECTIONS',
  SPACER: 'SPACER',
};

export const formatDate = rawDate => {
  const date = new window.Date(rawDate);
  const formattedDate = new Intl.DateTimeFormat('en-US', { month: 'long', day: 'numeric' }).format(
    date
  );
  return formattedDate;
};

export const areUnreadNotifications = newsTimestamps => {
  const lastRead =
    typeof window === 'undefined' ? false : window.localStorage.getItem('lastViewedWhatIsNew');
  if (lastRead) {
    // if any of the dates are after the last read timestamp, they have unread notifications
    for (let i = 0; i < newsTimestamps.length; i++) {
      if (newsTimestamps[i] > parseInt(lastRead)) {
        return true;
      }
    }
    // otherwise, they are caught up and return false
    return false;
  } else {
    // If there wasn't a last read timestamp, assume they haven't read notifications
    return true;
  }
};

export const setLastViewedWhatIsNew = () => {
  if (typeof window === 'undefined') {
    // do nothing
  } else {
    window.localStorage.setItem('lastViewedWhatIsNew', window.Date.now());
  }
};

const buySellDropdownInfo = [
  {
    text: 'Buy',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/exchange/buy',
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Sell',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/sell',
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Namebase Pro',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/pro',
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Wrapped HNS',
    type: navTypes.LINK,
    auxiliaryInfo: {
      path: '/wrapped-hns',
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
];

const showIntercom = closeNav => {
  if (window && window.Intercom) {
    // Show the bubble
    window.Intercom('update', {
      hide_default_launcher: false,
    });

    // Show the messenger panel
    window.Intercom('show');

    if (closeNav) setTimeout(closeNav, 250);

    // Hide the bubble on close
    window.Intercom('onHide', function() {
      window.Intercom('update', {
        hide_default_launcher: true,
      });
    });
  }
};

const supportDropdownInfo = [
  {
    text: 'Chat',
    type: navTypes.ACTION,
    auxiliaryInfo: {
      onClick: showIntercom,
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Help Center',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: supportUrl,
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Discord',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: discordUrl,
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
];

const logout = () => {
  return post('/auth/logout', {}).then(() => {
    if (window.analytics) {
      window.analytics.reset();
    }
    window.location = '/';
  });
};

const accountDropdownInfo = [
  {
    text: 'Settings',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/settings',
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Log Out',
    type: navTypes.ACTION,
    auxiliaryInfo: {
      onClick: logout,
    },
    showWhen: showStates.ALWAYS,
  },
];

const aboutHnsDropdownInfo = [
  {
    text: 'About HNS',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: primerUrl,
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Learning Center',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: learnUrl,
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Use Cases',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/use-cases',
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },

  {
    text: 'Community',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: communityUrl,
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
  {
    text: 'Developer Guides',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: developersUrl,
      newTab: true,
    },
    showWhen: showStates.ALWAYS,
  },
];

export const shouldRenderComponent = (user, showWhen) => {
  const loggedIn = !!(user && user.email);

  if (showWhen === showStates.ALWAYS) {
    return true;
  } else if (showWhen === showStates.LOGGEDIN) {
    return loggedIn;
  } else if (showWhen === showStates.LOGGEDOUT) {
    return !loggedIn;
  }
};

// for active states on navbar
export const navSections = {
  DOMAINS: 'DOMAINS',
  AUCTIONS: 'AUCTIONS',
  RESOURCES: 'RESOURCES',
  DASHBOARD: 'DASHBOARD',
  BUYSELL: 'BUYSELL',
  LOGIN: 'LOGIN',
  SIGNUP: 'SIGNUP',
  NONE: 'NONE',
  ACCOUNT: 'ACCOUNT',
  SPLASH: 'SPLASH',
  EARN: 'EARN'
};

const navItemInfo = {
  DOMAINS: {
    text: 'Marketplace',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/domains',
    },
    activeSection: navSections.DOMAINS,
    showWhen: showStates.ALWAYS,
  },
  AUCTIONS: {
    text: 'Auctions',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/auctions',
    },
    activeSection: navSections.AUCTIONS,
    showWhen: showStates.ALWAYS,
  },
  ABOUT_HNS: {
    text: 'About HNS',
    type: navTypes.CATEGORY,
    auxiliaryInfo: {
      dropdownInfo: aboutHnsDropdownInfo,
    },
    activeSection: navSections.NONE,
    showWhen: showStates.ALWAYS,
  },
  RESOURCES: {
    text: 'Support',
    type: navTypes.CATEGORY,
    auxiliaryInfo: {
      dropdownInfo: supportDropdownInfo,
    },
    activeSection: navSections.RESOURCES,
    showWhen: showStates.ALWAYS,
  },
  BUYSELL: {
    text: 'Buy/Sell HNS',
    type: navTypes.CATEGORY,
    auxiliaryInfo: {
      dropdownInfo: buySellDropdownInfo,
    },
    activeSection: navSections.BUYSELL,
    showWhen: showStates.ALWAYS,
  },
  DASHBOARD: {
    text: 'Dashboard',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/dashboard',
    },
    activeSection: navSections.DASHBOARD,
    showWhen: showStates.LOGGEDIN,
  },
  LOGIN: {
    text: 'Log In',
    type: navTypes.TRADITIONAL_LINK,
    auxiliaryInfo: {
      path: '/login',
      // TODO make solution for this in gatsby app
      // withState: true,
    },
    activeSection: navSections.LOGIN,
    showWhen: showStates.LOGGEDOUT,
  },
  REGISTER: {
    text: 'Sign Up',
    type: navTypes.BUTTON,
    auxiliaryInfo: {
      path: '/register',
      // TODO make solution for this in gatsby app
      // withState: true,
    },
    activeSection: navSections.NONE,
    showWhen: showStates.LOGGEDOUT,
  },
  NOTIFICATIONS: {
    text: "What's new",
    type: navTypes.NEWS,
    auxiliaryInfo: {
      Icon: Bell,
      dropdownInfo: accountDropdownInfo,
    },
    activeSection: navSections.NONE,
    showWhen: showStates.LOGGEDIN,
  },
  ACCOUNT: {
    text: 'Account',
    type: navTypes.CATEGORY,
    auxiliaryInfo: {
      dropdownInfo: accountDropdownInfo,
      Icon: Avatar,
    },
    activeSection: navSections.ACCOUNT,
    showWhen: showStates.LOGGEDIN,
  },
  EARN: {
    text: 'Earn HNS',
    type: navTypes.LINK,
    auxiliaryInfo: {
      path: '/earn',
    },
    activeSection: navSections.EARN,
    showWhen: showStates.ALWAYS,
  },
  SPACER: {
    text: '',
    type: navTypes.SPACER,
    auxiliaryInfo: {
      Icon: "<div></div>",
    },
    activeSection: navSections.NONE,
    showWhen: showStates.LOGGEDIN,
  },
};

export const desktopNavItems = [
  navItemInfo.DASHBOARD,
  navItemInfo.DOMAINS,
  navItemInfo.AUCTIONS,
  navItemInfo.BUYSELL,
  navItemInfo.ABOUT_HNS,
  navItemInfo.RESOURCES,
  // navItemInfo.EARN,
  navItemInfo.LOGIN,
  navItemInfo.REGISTER,
  navItemInfo.SPACER,
  // navItemInfo.NOTIFICATIONS,
  navItemInfo.ACCOUNT,
];

export const mobileNavItems = {
  upperItems: [
    navItemInfo.DASHBOARD,
    navItemInfo.DOMAINS,
    navItemInfo.AUCTIONS,
    navItemInfo.BUYSELL,
    navItemInfo.ABOUT_HNS,
    navItemInfo.RESOURCES,
    // navItemInfo.EARN
  ],
  lowerItems: [
    navItemInfo.LOGIN,
    navItemInfo.REGISTER,
    // navItemInfo.NOTIFICATIONS,
    navItemInfo.ACCOUNT,
  ],
};

export const actions = {
  entered: 'entered',
  left: 'left',
};
