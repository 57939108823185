import React from 'react';
import styled from 'styled-components';
import { Small } from '../text';
import { formatDate } from './navItems';
import { PopoutSpacer, PopoutTriangle, PopoutBody } from './NavbarPopout';

const NewsPopoutBody = styled(PopoutBody)`
  top: calc(100% - 8px);
  left: initial;
  width: 400px;
  right: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => theme.spacing(1)} 0;
  display: flex;
  flex-direction: column;
`;

const WhatNewTitle = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 700;
  color: ${props => props.theme.color.nbPrimary};
  padding: 0 ${props => props.theme.spacing(3)};
  height: ${props => props.theme.spacing(6)};
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.color.grey30};
`;

export const NewsPopout = ({ news, closeNews, show }) => {
  const contentRef = React.useRef(null);
  const [triangleOffset, setTriangleOffset] = React.useState(0);

  React.useLayoutEffect(() => {
    if (show && contentRef && contentRef.current) {
      const { x, width } = contentRef.current.parentElement.getBoundingClientRect();
      setTriangleOffset(x + width / 2);
    }
  }, [show]);

  // make news close when clicked outside
  React.useEffect(() => {
    const closeIfNotClickedHandler = e => {
      const wrapper = document.getElementById('news');
      if (wrapper && !wrapper.contains(e.target)) closeNews();
    };

    if (show) {
      document.addEventListener('click', closeIfNotClickedHandler);
    } else {
      document.removeEventListener('click', closeIfNotClickedHandler);
    }

    return () => {
      document.removeEventListener('click', closeIfNotClickedHandler);
    };
  }, [closeNews, show]);

  if (!show) return null;
  return (
    <React.Fragment>
      <PopoutSpacer />
      <PopoutTriangle style={{ top: "calc(100% - 16px)", left: `${triangleOffset - 8}px` }}/>
      <NewsPopoutBody ref={contentRef} id="news">
        <WhatNewTitle>WHAT'S NEW</WhatNewTitle>
        <NewsBodyWrapper>
          {news.map(info => {
            const { date, title, news: newsText } = info;
            return <NewsComponent key={title} date={date} title={title} news={newsText.news} />;
          })}
        </NewsBodyWrapper>
      </NewsPopoutBody>
    </React.Fragment>
  );
};

const NewsBodyWrapper = styled.div`
  padding: 0 ${props => props.theme.spacing(3)};
  max-height: 365px;
  overflow: auto;
`;

const NewsComponentWrapper = styled.div`
  padding: ${props => props.theme.spacing(3)} 0;
  &:not(:last-of-type) {
    border-bottom: 1px solid ${props => props.theme.color.nbPrimary};
  }
`;

const Date = styled.time`
  font-weight: 300;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: ${props => props.theme.color.grey70};
`;

const NewsTitle = styled(Small).attrs(props => ({ weight: 'bold' }))``;

const NewsInfo = styled.div`
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${props => props.theme.color.grey70};
  white-space: normal;
`;

const NewsLink = styled.a`
  color: ${props => props.theme.color.nbPrimary};
`;

export const renderNewsWithLinks = (newsText, children = null) => {
  // regex from https://davidwells.io/snippets/regex-match-markdown-links
  const regexMdLinks = /\[([^\[]+)\](\(.*\))/gm;

  const matches = newsText.match(regexMdLinks);

  const singleMatch = /\[([^\[]+)\]\((.*)\)/;
  if (matches) {
    const matchesArray = [];
    for (let i = 0; i < matches.length; i++) {
      const match = singleMatch.exec(matches[i]);
      const text = match[0];
      const word = match[1];
      const link = match[2];
      matchesArray.push({
        text: text,
        word: word,
        link: link,
      });
    }

    let currentStringIndex = 0;
    const componentsToRender = [];
    for (let i = 0; i < matchesArray.length; i++) {
      const textIndex = newsText.indexOf(matchesArray[i].text);
      // push the text up until this point as regular text:
      componentsToRender.push(
        <React.Fragment key={i + '_text'}>
          {newsText.substring(currentStringIndex, textIndex - 1)}
        </React.Fragment>
      );

      // update the current index
      currentStringIndex += textIndex - currentStringIndex;

      // now push the link
      componentsToRender.push(
        <NewsLink href={matchesArray[i].link} key={i + '_link'}>
          {' ' + matchesArray[i].word + ' '}
          {children}
        </NewsLink>
      );

      // update the current index
      currentStringIndex += matchesArray[i].text.length;

      // if it was the last match, and there's more text at the end, add it
      if (i === matchesArray.length - 1) {
        componentsToRender.push(
          <React.Fragment key={i + '_finalText'}>
            {newsText.substring(currentStringIndex)}
          </React.Fragment>
        );
      }
    }

    return componentsToRender;
  } else {
    return newsText;
  }
};

const NewsComponent = ({ date, title, news }) => {
  return (
    <NewsComponentWrapper>
      <Date>{formatDate(date)}</Date>
      <NewsTitle>{title}</NewsTitle>
      <NewsInfo>{renderNewsWithLinks(news)}</NewsInfo>
    </NewsComponentWrapper>
  );
};
