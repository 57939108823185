import styled from 'styled-components';

export const FooterRoot = styled.footer`
  background-color: ${({ theme }) => theme.color.brandDark};
  padding: ${({ theme }) => theme.spacing(7)};
  position: relative;

  @media ${props => props.theme.breakpoints.sm} {
    padding-left: ${({ theme }) => theme.spacing(3)};
    padding-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export const FooterNav = styled.nav`
  margin: 0 auto;
  padding-top: 20px;
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-right: ${({ theme }) => theme.spacing(8)};
  }

  @media ${props => props.theme.breakpoints.md} {
    & > * {
      margin-right: 15%;
    }
  }  

  @media ${props => props.theme.breakpoints.sm} {
    & > * {
      margin-right: 5%;
    }
  }  
`;

export const LinksGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.spacing(4)};
  width: ${({ theme }) => theme.spacing(23)};

  & > * + * {
    margin-top: ${({ theme }) => theme.spacing(2)};
  }

  @media ${props => props.theme.breakpoints.sm} {
    width: ${({ theme }) => theme.spacing(30)};
    & > * + * {
      margin-top: ${({ theme }) => theme.spacing(3)};
    }
  }
`;

export const LinksGroupHeader = styled.div`
  font-size: 13px;
  letter-spacing: 1px;
  line-height: 16px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(2)};
  color: ${({ theme }) => theme.color.grey50};
`;

export const Link = styled.div`
  line-height: 22px;
  font-size: 16px;
  color: ${({ theme }) => theme.color.white};

  &:hover {
    color: ${({ theme }) => theme.color.yellowLight};
  }
`;

export const SocialsWrapper = styled.div`
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: ${({ theme }) => theme.spacing(7)};
  & > * + * {
    margin-left: ${({ theme }) => theme.spacing(2)};
  }

  @media ${props => props.theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }
`;

export const CopywriteNamebase = styled.div`
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.color.white};
  margin-top: ${({ theme }) => theme.spacing(6)};
  opacity: 0.7;

  @media ${props => props.theme.breakpoints.md} {
    margin-top: ${({ theme }) => theme.spacing(8)};
  }
`;

export const ScrollToTopButton = styled.button`
  background: none;
  border: none;

  &:hover {
    * {
      stroke: ${({ theme }) => theme.color.grey30};
    }
  }
`;
