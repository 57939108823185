import { liz } from './packages/NamebaseUi/theme';

const unit = 8;

export const theme = {
  ...liz,
  color: {
    ...liz.color,
    white: '#FFFFFF',
    black: '#000000',
    almostBlack: '#111111',
    burntUmber: '#222222',
    handshakeLogoBlack: '#0C0C0C',
    coinGeckoGreen: '#8DC647',
    twitterBlue: '#1DA1F2',
    twitterPink: '#E0245E',
    twitterGrey: '#556470',
    twitterGreen: '#17BF63',
    twitterLightGrey: '#E0E0E0',
    discordPurple: '#7289DA',
    nomicsPurple: '#9E37F6',
    nbPrimary: '#0068FF',
    nbPrimaryDark: '#0057d7',
    nbPrimaryLight: '#007FFF',
    darkText: '#091F43',
    greyText: '#8894A8',
    grey60: '#8894A8',
    borderGrey: '#E1E4E8',
    placeholderText: '#a4aebd',
    red: '#f10013',
    redDark: '#e61a2f',
    grey10: '#F8F9FB',
    dmGrey10: '#141414',
    grey20: '#EEF0F5',
    dmGrey20: '#242424',
    grey30: '#D4DAE2',
    dmGrey30: '#383838',
    grey50: '#A4AEBD',
    dmGrey50: '#676767',
    grey70: '#738198',
    grey80: '#657287',
    grey90: '#545E6F',
    proBrand: '#49FBD4',
    proBrandDark: '#2FA78C',
    blueDark: '#1E2632',
    blue90: '#071834',
    blue60: '#0049b6',
    blue50: '#0056d7',
    blue40: '#0063f2',
    green: '#00CD8F',
    greenLight: '#28EAC2',
    yellowLight: '#FFC73A',
    // TODO this is the same as the dark text color
    brandDark: '#091F43',
  },
  breakpoints: {
    ...liz.breakpoints,
    xl: `(min-width: ${unit * 180}px)`,
    xlMin: `(max-width: calc(${unit * 180}px - 1px))`,
    lg: `(min-width: ${unit * 160}px)`,
    md: `(max-width: calc(${unit * 160}px - 1px))`,
    sm: `(max-width: calc(${unit * 96}px - 1px))`,
    xs: `(max-width: calc(${unit * 71}px - 1px))`,
  },
  numberColumns: 12,
};
