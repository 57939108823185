import React from 'react';
import Helmet from 'react-helmet';

import robotoVariableFont from '../../static/fonts/RobotoVariable-subset.woff2';
import robotoMonoRegularFont from '../../static/fonts/RobotoMono-regular.woff';
import robotoLightFont from '../../static/fonts/Roboto-light.woff2';
import robotoRegularFont from '../../static/fonts/Roboto-regular.woff2';
import robotoRegularItalicFont from '../../static/fonts/Roboto-regular-italic.woff2';
import robotoMediumFont from '../../static/fonts/Roboto-medium.woff2';
import robotoBoldFont from '../../static/fonts/Roboto-bold.woff2';

const SEO = ({
  lang = 'en',
  title,
  image,
  imageAlt = 'Namebase website and logo',
  imageWidth,
  imageHeight,
  description,
  schemaMarkup,
}) => (
  <Helmet htmlAttributes={{ lang }} title={title}>
    <meta
      name="viewport"
      content="width=device-width, initial-scale=0.86, maximum-scale=5.0, minimum-scale=0.86, viewport-fit=cover"
    />
    <meta
      name="robots"
      content="follow, index, max-snippet:-1, max-video-preview:-1, max-image-preview:large"
    />
    <meta
      name="google-site-verification"
      content="VuX3FcZjfyAjo6rmcaEHre2cOj1e1YqjKV2f9dL5Spw"
    />

    <meta name="og:image" content={image} />
    <meta name="og:image:alt" content={imageAlt} />
    <meta name="og:image:width" content={`${imageWidth}`} />
    <meta name="og:image:height" content={`${imageHeight}`} />
    <meta name="og:image:secure_url" content={image} />

    <meta name="description" content={description} />
    <meta name="og:description" content={description} />

    <meta name="og:type" content="website" />
    <meta name="og:title" content={title} />
    <meta name="og:locale" content="en_US" />
    <meta name="og:site_name" content="Namebase" />

    <meta name="twitter:site" content="@NamebaseHQ" />
    <meta name="twitter:card" content="summary_large_image" />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:image" content={`https:${image}`} />
    <meta name="twitter:creator" content="@NamebaseHQ" />
    <meta name="twitter:description" content={description} />

    {/* fonts */}
    <link rel="preload" as="font" href={robotoVariableFont} type="font/woff2" />
    <link rel="preload" as="font" href={robotoMonoRegularFont} type="font/woff" />
    <link rel="preload" as="font" href={robotoLightFont} type="font/woff2" />
    <link rel="preload" as="font" href={robotoRegularFont} type="font/woff2" />
    <link rel="preload" as="font" href={robotoRegularItalicFont} type="font/woff2" />
    <link rel="preload" as="font" href={robotoMediumFont} type="font/woff2" />
    <link rel="preload" as="font" href={robotoBoldFont} type="font/woff2" />

    {/* 3rd-part preconnects */}
    <link rel="preconnect" href="https://widget.intercom.io" crossOrigin />
    <link rel="preconnect" href="https://js.intercomcdn.com" crossOrigin />
    <link rel="preconnect" href="https://static.intercomassets.com/" crossOrigin />
    <link rel="preconnect" href="https://api.segment.io" crossOrigin />

    {Boolean(schemaMarkup) && typeof schemaMarkup === 'string' ? (
      <script type="application/ld+json">{schemaMarkup}</script>
    ) : null}
  </Helmet>
);

const buildBlogPostSchema = (blogPost, pageMeta) => {
  const markup = {
    '@type': 'BlogPosting',
    '@context': 'https://schema.org',
    url: `https://www.namebase.io/blog/${blogPost.slug}`,
    image: pageMeta.jsonLdSchemas.image,
    author: {
      '@type': 'Person',
      '@context': 'https://schema.org',
      url: blogPost.author.twitterHandle ? `https://twitter.com/${blogPost.author.twitterHandle}` : 'https://www.namebase.io/about/',
      name: `${blogPost.author.name}`,
    },
    publisher: pageMeta.jsonLdSchemas.publisher,
    headline: `${blogPost.title}`,
    alternativeHeadline: `${blogPost.subtitle.subtitle}`,
    dateCreated: `${blogPost.createdAt}`,
    dateModified: `${blogPost.updatedAt}`,
    datePublished: `${blogPost.createdAt}`,
  };

  return markup;
};

export default SEO;
export { buildBlogPostSchema };
