import React from 'react';
import PropTypes from 'prop-types';
import { IconWrapper } from '../components/IconWrapper';

export const Chevron = ({ direction }) => (
  <IconWrapper direction={direction}>
    <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        className="chevron"
        d="M17.2251 1.5428L16.4951 0.774439C16.303 0.582348 15.9957 0.582348 15.842 0.774439L8.88835 7.72811L1.89626 0.774439C1.74259 0.582348 1.43524 0.582348 1.24315 0.774439L0.513208 1.5428C0.321118 1.69647 0.321118 2.00382 0.513208 2.19591L8.54259 10.2253C8.73468 10.4174 9.0036 10.4174 9.19569 10.2253L17.2251 2.19591C17.4172 2.00382 17.4172 1.69647 17.2251 1.5428Z"
        fill="#022047"
      />
    </svg>
  </IconWrapper>
);

Chevron.propTypes = {
  direction: PropTypes.string,
};
