import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Link } from 'gatsby';

import { Small } from '../text';
import { events } from '../../utils/trackEvent';
import { navTypes } from './navItems';

const fadeInAnimation = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

export const PopoutSpacer = styled.div`
  left: -${({ theme }) => theme.spacing(4)};
  width: calc(100% + ${({ theme }) => theme.spacing(4)});
  height: ${({ theme }) => theme.spacing(2.5)};
  cursor: auto;
  position: absolute;
  background: transparent;
`;

export const PopoutBody = styled.div`
  top: calc(100% + ${({ theme }) => theme.spacing(2.5)});
  left: -${({ theme }) => theme.spacing(4)};
  cursor: auto;
  z-index: 100;
  padding: ${({ theme }) => theme.spacing(3)} ${({ theme }) => theme.spacing(4)};
  position: absolute;
  animation: ${fadeInAnimation} 0.2s ease-in-out;
  box-shadow: ${({ theme }) => theme.shadows.dropdown};
  background: ${({ theme }) => theme.color.white};
  white-space: nowrap;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
`;

export const PopoutTriangle = styled.div`
  top: -${({ theme }) => theme.spacing(1)};
  left: ${({ theme }) => theme.spacing(4)};
  width: ${({ theme }) => theme.spacing(2)};
  height: ${({ theme }) => theme.spacing(2)};
  z-index: 101;
  position: absolute;
  transform: rotate(45deg);
  background: ${({ theme }) => theme.color.white};
  box-shadow: -5px -5px 8px 0px rgba(0, 0, 0, 0.075);
`;

const LinkText = styled(Small)`
  width: 100%;
  cursor: pointer;
  transition: ${({ theme }) => theme.transitions.color};
  line-height: 2.29;

  &:hover {
    color: ${({ theme }) => theme.color.greyText};
  }
`;

export const NavbarPopoutLink = ({ dropdownInfo }) => {
  const { text, auxiliaryInfo, type } = dropdownInfo;

  if (type === navTypes.LINK) {
    return (
      <Link
        to={auxiliaryInfo.path}
        onClick={() => { events.navbar.link.send(text) }}>
        <LinkText weight="light">{text}</LinkText>
      </Link>
    );
  } else if (type === navTypes.TRADITIONAL_LINK) {
    const { path, newTab } = auxiliaryInfo;
    return (
      <a
        onClick={() => { events.navbar.link.send(text) }}
        key={text}
        href={path}
        target={newTab ? '_blank' : null}
        rel={newTab ? 'noopener noreferrer' : null}>
        <LinkText weight="light">{text}</LinkText>
      </a>
    );
  } else if (type === navTypes.ACTION) {
    return (
      <div onClick={() => { events.navbar.link.send(text) }}>
        <LinkText weight="light" onClick={auxiliaryInfo.onClick}>{text}</LinkText>
      </div>
    );
  }
};

export const NavbarPopout = ({ show, children, ...props }) => {
  if (!show) return null;
  return(
    <React.Fragment>
      <PopoutSpacer />
      <PopoutBody {...props}>
        <PopoutTriangle />
        {children}
      </PopoutBody>
    </React.Fragment>
  )
}