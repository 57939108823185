import React from 'react';

import { SocialIconWrapper } from './SocialIconWrapper';
import { theme } from '../../../theme';

export const TwitterSocialIcon = props => (
  <SocialIconWrapper {...props} hoverColor={theme.color.twitterBlue}>
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="20" cy="20" r="20" fill="#0F2E64" />
      <path fill="white" d="M16.2,28.3c7.9,0,12.2-6.5,12.2-12.2c0-0.2,0-0.4,0-0.6c0.8-0.6,1.6-1.4,2.1-2.2c-0.8,0.3-1.6,0.6-2.5,0.7
	c0.9-0.5,1.6-1.4,1.9-2.4c-0.8,0.5-1.7,0.8-2.7,1c-0.8-0.8-1.9-1.4-3.1-1.4c-2.4,0-4.3,1.9-4.3,4.3c0,0.3,0,0.7,0.1,1
	c-3.6-0.2-6.7-1.9-8.8-4.5c-0.4,0.6-0.6,1.4-0.6,2.2c0,1.5,0.8,2.8,1.9,3.6c-0.7,0-1.4-0.2-1.9-0.5c0,0,0,0,0,0.1
	c0,2.1,1.5,3.8,3.4,4.2c-0.4,0.1-0.7,0.2-1.1,0.2c-0.3,0-0.5,0-0.8-0.1c0.5,1.7,2.1,2.9,4,3c-1.5,1.1-3.3,1.8-5.3,1.8
	c-0.3,0-0.7,0-1-0.1C11.5,27.6,13.7,28.3,16.2,28.3z"/>
    </svg>
  </SocialIconWrapper>
);
