import { useState, useEffect } from 'react';
import { get } from './requestFunctions';

export const useUser = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    get('/api/user').then(user => {
      if (window.analytics && window.analytics.identify) {
        window.analytics.identify(
          user.segmentUuid,
          { email: user.email },
          { integrations: { Intercom: { user_hash: user.intercomHmac } } }
        );
      }

      setUser(user);
    });
  }, [get, setUser]);

  return user;
};
