import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Small } from '../text';
import { events } from '../../utils/trackEvent';
import { RightAnchoredPopout } from './RightAnchoredPopout';
import { NewsPopout } from './NewsPopout';
import { navTypes, navSections, areUnreadNotifications, setLastViewedWhatIsNew } from './navItems';
import { Button } from '../Button';
import { navColors, navColorOptions } from './navColors';
import { NavbarPopout, NavbarPopoutLink } from './NavbarPopout';

const NavItemWrapper = styled.div`
  flex-shrink: 0;
  margin-right: ${props => props.theme.spacing(7)};
  &:last-of-type {
    margin-right: 0;
  }
`;

const NavItemSpacer = styled.div`
  flex-shrink: 0;
  margin-right: ${props => props.theme.spacing(3)};
`;

const NewsItemWrapper = styled(NavItemWrapper)`
  margin-right: ${props => props.theme.spacing(3)};
  margin-left: ${props => props.theme.spacing(-2)};
`;

export const NavLink = styled(Small)`
  transition: ${props => props.theme.transitions.color};
  position: relative;
  color: ${props => (props.makeBlack ? props.theme.color.darkText : props.theme.color.greyText)};
  color: ${props =>
    props.makeBlack
      ? props.navColor
        ? navColors[props.navColor].text.active
        : navColors[navColorOptions.default].text.active
      : props.navColor
      ? navColors[props.navColor].text.inactive
      : navColors[navColorOptions.default].text.inactive};
  cursor: pointer;
`;

const PopoutCategorySpacing = styled.div`
  &:not(:last-of-type) {
    margin-right: ${({ theme }) => theme.spacing(4)};
  }
`;

const PopoutCategoryTitle = styled.div`
  color: ${({ theme }) => theme.color.darkText};
  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
`;

export const NavLinkComponent = ({
  news,
  user,
  index,
  navItem,
  actions,
  navColor,
  loginRedirect,
  navItemHovered,
  hoveredNavItems,
  currentNavSection,
  dispatchMouseAction,
  shouldRenderComponent,
}) => {
  const [isNewsShowing, setIsNewsShowing] = useState(false);
  const { type, text, auxiliaryInfo, activeSection, showWhen } = navItem;
  const shouldRender = shouldRenderComponent(user, showWhen);
  if (!shouldRender) return null;
  const active = currentNavSection === activeSection;
  const makeBlack =
    hoveredNavItems[index] ||
    (active && !navItemHovered) ||
    (!navItemHovered && currentNavSection === navSections.SPLASH);

  const defaultProps = {
    weight: 'medium',
    onMouseEnter: () => dispatchMouseAction({ type: actions.entered, itemIndex: index }),
    onMouseLeave: () => dispatchMouseAction({ type: actions.left }),
    navColor: navColor,
    makeBlack,
  };

  if (type === navTypes.LINK) {
    const { path, withState } = auxiliaryInfo;
    return (
      <NavItemWrapper
        onClick={() => {
          events.navbar.link.send(text);
        }}>
        <Link to={withState ? { pathname: path, state: { from: loginRedirect } } : path}>
          <NavLink {...defaultProps}>{text}</NavLink>
        </Link>
      </NavItemWrapper>
    );
  } else if (type === navTypes.CATEGORY) {
    const { Icon } = auxiliaryInfo;
    return (
      <NavItemWrapper>
        <NavLink {...defaultProps} style={{ position: Icon ? 'static' : 'relative' }}>
          {Icon ? <Icon /> : text}
          {Icon ? (
            <RightAnchoredPopout
              show={hoveredNavItems[index]}
              dropdownInfo={auxiliaryInfo.dropdownInfo}
            />
          ) : (
            <NavbarPopout show={hoveredNavItems[index]}>
              {auxiliaryInfo.dropdownInfo.map(info => (
                <NavbarPopoutLink key={info.text} dropdownInfo={info} />
              ))}
            </NavbarPopout>
          )}
        </NavLink>
      </NavItemWrapper>
    );
  } else if (type === navTypes.NEWS) {
    const { Icon } = auxiliaryInfo;
    const areUnread = areUnreadNotifications(news.map(item => item.date));
    return (
      <NewsItemWrapper>
        <NavLink {...defaultProps} style={{ position: 'static' }}>
          <Icon
            areUnread={areUnread}
            onClick={() => {
              setIsNewsShowing(true);
              if (user && user.email) {
                // only set viewed if user is logged in
                // (user shouldn't see this if they are logged out, but good to be safe and consistent anyway)
                setLastViewedWhatIsNew();
              }
            }}
          />
          <NewsPopout
            news={news}
            show={isNewsShowing}
            closeNews={() => { setIsNewsShowing(false) }}
          />
        </NavLink>
      </NewsItemWrapper>
    );
  } else if (type === navTypes.CATEGORY_WITH_SECTIONS) {
    return (
      <NavItemWrapper>
        <NavLink {...defaultProps}>
          {text}
          <NavbarPopout show={hoveredNavItems[index]} style={{ display: "flex" }}>
            {auxiliaryInfo.dropdownInfo.map(info => (
              <PopoutCategorySpacing key={info.title}>
                <PopoutCategoryTitle>
                  {info.title}
                </PopoutCategoryTitle>
                {info.items.map(info => (
                  <NavbarPopoutLink dropdownInfo={info} key={info.text} />
                ))}
              </PopoutCategorySpacing>
            ))}
          </NavbarPopout>
        </NavLink>
      </NavItemWrapper>
    );
  } else if (type === navTypes.BUTTON) {
    const buttonType = navColor
      ? navColors[navColor].buttonType
      : navColors[navColorOptions.default].buttonType;
    return (
      // note that buttons don't need default props since they already have their own hover effects and font-weight, which is what the default props govern
      <NavItemWrapper
        onClick={() => {
          events.navbar.link.send(text);
        }}>
        <Button variant={buttonType} as="a" href={auxiliaryInfo.path} rel="noopener noreferrer">
          {text}
        </Button>
      </NavItemWrapper>
    );
  } else if (type === navTypes.ACTION) {
    return (
      <NavItemWrapper onClick={() => {}}>
        <NavLink {...defaultProps} onClick={auxiliaryInfo.onClick}>
          {text}
        </NavLink>
      </NavItemWrapper>
    );
  } else if (type === navTypes.TRADITIONAL_LINK) {
    return (
      <NavItemWrapper
        onClick={() => {
          events.navbar.link.send(text);
        }}>
        <a href={auxiliaryInfo.path}>
          <NavLink {...defaultProps}>{text}</NavLink>
        </a>
      </NavItemWrapper>
    );
  } else if (type === navTypes.SPACER) {
    return (
      <NavItemSpacer>
        <div style={{width: '1px'}}></div>
      </NavItemSpacer>
    );
  }
};
