import React from 'react';

export const NamebaseLogoSvg = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.1428 7.21429C28.7207 7.21429 29.9999 5.9351 29.9999 4.35714C29.9999 2.77919 28.7207 1.5 27.1428 1.5C25.5648 1.5 24.2856 2.77919 24.2856 4.35714C24.2856 5.9351 25.5648 7.21429 27.1428 7.21429Z"
      fill="url(#paint0_linear_footer)"
    />
    <path
      d="M16 5C23.1797 5 29 10.8203 29 18V30H21V18C21 15.2386 18.7614 13 16 13V5Z"
      fill="#007FFF"
    />
    <path
      d="M16 5C8.8203 5 3 10.8203 3 18V30H11V18C11 15.2386 13.2386 13 16 13V5Z"
      fill="#FFB700"
    />
    <defs>
      <linearGradient
        id="paint0_linear_footer"
        x1="21.6047"
        y1="4.34818"
        x2="27.1338"
        y2="9.87731"
        gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF5980" />
        <stop offset="1" stopColor="#FFA882" />
      </linearGradient>
    </defs>
  </svg>
);
